import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import {
    getCancellationRuleSetVersion,
    isUpdatingCancellationRuleSetVersion,
    isCreatingCancellationRuleSetVersion,
    isLoadingCancellationRuleSetVersionById,
    fetchCancellationRuleSetVersionById,
    updateCancellationRuleSetVersion,
    createCancellationRuleSetVersion
} from '../../../reducers/cancellationRuleSetReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import GenericStepper from '../../common/widgets/genericStepper';
import CancellationRuleSetVersion from '../../../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleSetVersionStep1Validation from '../validations/cancellationRuleSetVersionStep1Validation';
import CancellationRuleSetVersionFormStep1 from './cancellationRuleSetVersionFormStep1';

interface ICancellationRuleSetVersionFormProps {
    cancellationRuleSetVersionId?: number;
    cancellationRuleSetId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit cancellation rule set version'),
    add: LanguageUtils.createMessage('Add cancellation rule set version')
};

export default function CancellationRuleSetVersionForm(props: ICancellationRuleSetVersionFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { cancellationRuleSetVersionId, steps, type, cancellationRuleSetId } = props;
    const [cancellationRuleSetVersion, setCancellationRuleSetVersion] = React.useState<CancellationRuleSetVersion>({
        id: cancellationRuleSetVersionId,
        cancellationRuleSetId: cancellationRuleSetId
    } as CancellationRuleSetVersion);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsCancellationRuleSetVersion = useSelector(getCancellationRuleSetVersion);
    const propsIsUpdatingCancellationRuleSetVersion = useSelector(isUpdatingCancellationRuleSetVersion);
    const prevIsUpdatingCancellationRuleSetVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCancellationRuleSetVersion);
    const propsIsCreatingCancellationRuleSetVersion = useSelector(isCreatingCancellationRuleSetVersion);
    const prevIsCreatingCancellationRuleSetVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCancellationRuleSetVersion);
    const propsIsLoadingCancellationRuleSetVersion = useSelector(isLoadingCancellationRuleSetVersionById);
    const prevIsLoadingCancellationRuleSetVersion = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCancellationRuleSetVersion);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingCancellationRuleSetVersion === true && !propsIsUpdatingCancellationRuleSetVersion) ||
                    (prevIsCreatingCancellationRuleSetVersion && !propsIsCreatingCancellationRuleSetVersion)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingCancellationRuleSetVersion, propsIsCreatingCancellationRuleSetVersion]);

    useEffect(() => {
        if(cancellationRuleSetVersionId) {
            dispatch(fetchCancellationRuleSetVersionById(cancellationRuleSetVersionId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingCancellationRuleSetVersion && !propsIsLoadingCancellationRuleSetVersion) {
            setCancellationRuleSetVersion(propsCancellationRuleSetVersion);
        }

    }, [propsIsLoadingCancellationRuleSetVersion]);

    const onChange = (attribute: string, value: any) => {
        const newCancellationRuleSetVersion = { ...cancellationRuleSetVersion } as CancellationRuleSetVersion;
        (newCancellationRuleSetVersion as any)[attribute] = value;

        setCancellationRuleSetVersion(newCancellationRuleSetVersion);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <CancellationRuleSetVersionFormStep1 onChange={onChange} cancellationRuleSetVersion={cancellationRuleSetVersion} />,
            validationFn: () => CancellationRuleSetVersionStep1Validation.validateCancellationRuleSetVersionForm(cancellationRuleSetVersion),
            onNext: () => {
                if(!cancellationRuleSetVersion.id) {
                    dispatch(createCancellationRuleSetVersion(cancellationRuleSetVersion));
                }
                else {
                    dispatch(updateCancellationRuleSetVersion(cancellationRuleSetVersion));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.CANCELLATION_RULE_SET}/${cancellationRuleSetId}/versions`;

            case FormType.Edit:
                return `/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${cancellationRuleSetVersion.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
