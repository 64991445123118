import Customers from './components/customers/customers';
import React from 'react';
import Root from './root';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import UrlConstants from './constants/UrlConstants';
import theme from './theme';
import { hot } from 'react-hot-loader/root';
import ViewCustomerDetails from './components/customers/customerDetails';
import Agrements from './components/agreements/agreements';
import AddCustomer from './components/customers/addCustomer';
import viewAgreementDetails from './components/agreements/agreementDetails';
import AddAgreement from './components/agreements/addAgreement';
import { ThemeProvider } from '@mui/material/styles';
import AddCustomerAgreement from './components/customerAgreements/addCustomerAgreement';
import AddAgreementVersionDiscount from './components/agreements/agreementVersion/addAgreementVersionDiscount';
import products from './components/products/products';
import AddProduct from './components/products/addProduct';
import productDetails from './components/products/productDetails';
import pricelists from './components/pricelists/pricelists';
import pricelistDetails from './components/pricelists/pricelistDetails';
import AddProductDynamics from './components/products/addProductDynamics';
import AddPriceListVersion from './components/pricelists/addPriceListVersion';
import factorSetWeights from './components/factorSets/weight/factorSetWeights';
import factorSetsPriority from './components/factorSets/priority/factorSetsPriority';
import factorSetDepartures from './components/factorSets/departure/factorSetDepartures';
import AddPricelist from './components/pricelists/addPricelist';
import corporates from './components/corporates/corporates';
import AddCorporate from './components/corporates/addCorporate';
import ViewCustomerCorporate from './components/corporates/viewCustomerCorporate';
import factorSetWeightDetails from './components/factorSets/weight/factorSetWeightDetails';
import AddFactorSetWeight from './components/factorSets/weight/add/addFactorSetWeight';
import factorSetPriorityDetails from './components/factorSets/priority/factorSetPriorityDetails';
import factorSetDepartureDetails from './components/factorSets/departure/factorSetDepartureDetails';
import AddFactorSetDeparture from './components/factorSets/departure/add/addFactorSetDeparture';
import AddFactorSetPriority from './components/factorSets/priority/add/addFactorSetPriority';
import AddPricelistVersionProduct from './components/pricelistVersionProducts/addPricelistVersionProduct';
import AddAgreementVersionPricelist from './components/agreementVersionPricelist/addAgreementVersionPricelist';
import gtsOrders from './components/gtsOrders/gtsOrders';
import AddFactorSetWeightLine from './components/factorSets/weight/add/addFactorSetWeightLine';
import AddFactorSetPriorityLine from './components/factorSets/priority/add/addFactorSetPriorityLine';
import AddFactorSetDepartureLine from './components/factorSets/departure/add/addFactorSetDepartureLine';
import gtsOrderDetails from './components/gtsOrders/gtsOrderDetails';
import AddGtsOrder from './components/gtsOrders/addOrder';
import gtsOrderUnitDetails from './components/gtsOrderUnits/gtsOrderUnitDetails';
import unitServiceDetails from './components/gtsOrderUnits/unitServiceDetails';
import unitDangerousGoodsDetails from './components/gtsOrderUnits/unitDangerousGoodsDetails';
import unitDepotDetails from './components/gtsOrderUnits/unitDepotDetails';
import orders from './components/orders/orders';
import orderDetails from './components/orders/orderDetails';
import AddOrder from './components/orders/addOrder';
import services from './components/services/services';
import serviceDetails from './components/services/serviceDetails';
import orderUnitDetails from './components/orderUnits/orderUnitDetails';
import AddService from './components/services/addService';
import orderUnitServiceDetails from './components/orderUnits/orderUnitServiceDetails';
import orderUnitServiceProductDetails from './components/orderUnits/orderUnitServiceProducts/orderUnitServiceProductDetails';
import Dashboard from './components/dashboard/dashboard';
import { FormType } from './constants/constants';
import AddOrderUnitServiceProduct from './components/orders/orderUnitServiceProducts/orderUnitServiceProduct';
import AddOrderUnitService from './components/orders/orderUnitServices/addOrderUnitService';
import AddGtsOrderUnit from './components/gtsOrderUnits/addGtsOrderUnit';
import ImportGtsOrder from './components/gtsOrders/importGtsOrder';
import factorSetCancellation from './components/factorSets/cancellation/factorSetCancellation';
import factorSetCancellationDetails from './components/factorSets/cancellation/factorSetCancellationDetails';
import factorSetHoursDetails from './components/factorSets/hours/factorSetHoursDetails';
import AddFactorSetHours from './components/factorSets/hours/add/addFactorSetHours';
import AddFactorSetHoursLine from './components/factorSets/hours/add/addFactorSetHoursLine';
import factorSetHours from './components/factorSets/hours/factorSetHours';
import AddFactorSetCancellation from './components/factorSets/cancellation/add/addFactorSetCancellation';
import AddFactorSetCancellationLine from './components/factorSets/cancellation/add/addFactorSetCancellationLine';
import agreementVersionSpecialPriceDetails from './components/agreementVersionSpecialPrice/agreementVersionSpecialPriceDetails';
import AddAgreementVersionSpecialPrice from './components/agreementVersionSpecialPrice/add/addAgreementVersionSpecialPrice';
import InvoiceDetails from './components/invoices/invoiceDetails';
import AddInvoice from './components/invoices/add/addInvoice';
import AddInvoiceLine from './components/invoices/add/addInvoiceLine';
import Simulations from './components/simulations/simulations';
import Invoices from './components/invoices/invoices';
import InvoiceLineDetails from './components/invoices/invoiceLines/invoiceLinesDetails';
import factorSetDangerousGoodsDetails from './components/factorSets/dangerousGoods/factorSetDangerousGoodsDetails';
import FactorSetDangerousGoods from './components/factorSets/dangerousGoods/factorSetDangerousGoods';
import factorSetCalendar from './components/factorSets/calendar/factorSetCalendar';
import factorSetCalendarDetails from './components/factorSets/calendar/factorSetCalendarDetails';
import AddFactorSetCalendar from './components/factorSets/calendar/add/addFactorSetCalendar';
import AddFactorSetDangerousGoods from './components/factorSets/dangerousGoods/add/addFactorSetDangerousGoods';
import AddFactorSetCalendarLine from './components/factorSets/calendar/add/addFactorSetCalendarLine';
import AddFactorSetDangerousGoodsLine from './components/factorSets/dangerousGoods/add/addFactorSetDangerousGoodsLine';
import AddGtsOrderUnitService from './components/gtsOrderUnits/addGtsOrderUnitService';
import AddGtsOrderUnitDepot from './components/gtsOrderUnits/addGtsOrderUnitDepot';
import AddGtsOrderUnitDangerousGoods from './components/gtsOrderUnits/addGtsOrderUnitDangerousGoods';
import OrderUnitServiceProductSurchargeDetails from './components/surcharges/surchargeDetails';
import AddOrderUnitServiceProductSurcharge from './components/surcharges/add/addOrderUnitServiceProductSurcharge';
import AgreementVersionTransitDiscountDetails from './components/transitDiscounts/transitDiscountDetails';
import SurchargeRates from './components/surchargeRate/surchargeRates';
import SurchargeRateDetails from './components/surchargeRate/surchargeRateDetails';
import AddSurchargeRate from './components/surchargeRate/add/addSurchargeRate';
import Users from './components/users/users';
import UserDetails from './components/users/userDetails';
import AddUser from './components/users/add/addUser';
import AddServiceProduct from './components/serviceproducts/add/addServiceProduct';
import AddTerminal from './components/terminals/add/addTerminal';
import TerminalDetails from './components/terminals/terminalDetails';
import Terminals from './components/terminals/terminals';
import ProductArenas from './components/productArenas/productArenas';
import AddProductArena from './components/productArenas/add/addProductArena';
import ProductArenaDetails from './components/productArenas/productArenaDetails';
import OrdersNotInvoiced from './components/reports/ordersNotYetInvoiced/ordersNotYetInvoiced';
import AddResponsibilityCenter from './components/responsabilitiesCenter/add/addResponsibilityCenter';
import ResponsibilitiesCenter from './components/responsabilitiesCenter/responsabilitiesCenter';
import ResponsibiliyCenterDetails from './components/responsabilitiesCenter/responsibiliyCenterDetails';
import PrintAgreementVersionPeriodDetails from './components/agreements/printAgreementVersion/printAgreementVersionPeriodDetails';
import OrdersNotYetInvoicedFullReport from './components/reports/ordersNotYetInvoiced/ordersNotYetInvoicedFullReport';
import Reports from './components/reports/reports';
import ProvisionCSV from './components/reports/provision/provisions';
import KickbackAgreementCSV from './components/reports/kickbackAgreement/kickbackAgreement';
import ReportCustomerTermsComponent from './components/reports/reportCustomerTerms/reportCustomerTerms';
import ReportHeader from './components/reports/reportsHeaders/reportHeader';
import ReportHeaderPivoted from './components/reports/reportsHeaders/reportHeadersPivoted';
import ReportLine from './components/reports/reportsHeaders/reportLine';
import ReportLineDetails from './components/reports/reportsHeaders/reportLineDetails';
import CapriScheduledJob from './components/capriJobScheduler/capriScheduledJob';
import AddCapriScheduledJob from './components/capriJobScheduler/add/addCapriScheduledJob';
import CapriScheduledJobDetails from './components/capriJobScheduler/capriScheduledJobDetails';
import CancellationRoleSet from './components/cancellationRuleSet/cancellationRuleSet/cancellationRuleSet';
import CancellationRuleSetDetails from './components/cancellationRuleSet/cancellationRuleSet/cancellationRuleSetDetails';
import CancellationRuleDetails from './components/cancellationRuleSet/cancellationRuleDetails';
import AddCancellationRuleSet from './components/cancellationRuleSet/cancellationRuleSet/addCancellationRuleSet';
import AddCancellationRuleSetVersion from './components/cancellationRuleSet/cancellationRuleSetVersion/addCancellationRuleSetVersion';
import CancellationRuleSetVersionDetails from './components/cancellationRuleSet/cancellationRuleSetVersion/cancellationRuleSetVersionDetails';

const AppRouter: React.FC = () => (
    <ThemeProvider theme={theme}>
        <HashRouter>
            <Root>
                <Switch>
                    <Route exact render={(props: any) => <Dashboard {...props} />} path={UrlConstants.DASHBOARD} />
                    <Route exact component={Customers} path={`/${UrlConstants.CUSTOMERS}`} />
                    <Route exact render={(props: any) => <AddCustomer {...props} steps={[1, 2, 3]} type={FormType.Edit} />} path={`/${UrlConstants.CUSTOMERS}/:customerId/edit`} />
                    <Route exact render={(props: any) => <AddCustomer {...props} steps={[1, 2, 3]} type={FormType.Add} />} path={`/${UrlConstants.CUSTOMERS}/add`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CUSTOMERS}/:customerId/add/order`} />
                    <Route exact render={(props: any) => <AddCustomerAgreement {...props} steps={[1, 3]} type={FormType.AddCustomerAgreementFromCustomer} />}
                        path={`/${UrlConstants.CUSTOMERS}/:customerId/add/customeragreement`} />
                    <Route exact render={(props: any) => <AddCustomerAgreement {...props} steps={[3]} type={FormType.Edit} />}
                        path={`/${UrlConstants.CUSTOMERS}/:customerId/customeragreement/:customerAgreementId/edit`} />
                    <Route exact render={(props: any) => <AddInvoice {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CUSTOMERS}/:customerId/add/invoice`} />
                    <Route exact path={`/${UrlConstants.CUSTOMERS}/:customerId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={ViewCustomerDetails} path={`/${UrlConstants.CUSTOMERS}/:customerId`} />

                    <Route exact path="/">
                        <Redirect to={`${UrlConstants.CORPORATES}`} />
                    </Route>
                    <Route exact component={corporates} path={`/${UrlConstants.CORPORATES}`} />
                    <Route exact render={(props: any) => <AddCorporate {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CORPORATES}/add`} />
                    <Route exact render={(props: any) => <AddCorporate {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CORPORATES}/:corporateId/edit`} />
                    <Route exact path={`/${UrlConstants.CORPORATES}/:corporateId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.CORPORATES}/${props.match.params.corporateId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={ViewCustomerCorporate} path={`/${UrlConstants.CORPORATES}/:corporateId`} />

                    <Route exact component={Agrements} path={`/${UrlConstants.AGREEMENTS}`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.AGREEMENTS}/add`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.AGREEMENTS}/:agreementId/edit`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[1, 2]} type={FormType.Edit} />} path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/edit`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[5]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/status`} />
                    <Route exact render={(props: any) => <AddAgreementVersionDiscount {...props} steps={[1]} type={FormType.Add} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/add/discount`} />
                    <Route exact render={(props: any) => <AddAgreementVersionDiscount {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/discountId/:discountId/edit`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[2, 3, 4, 6]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/add/version`} />
                    <Route exact render={(props: any) => <AddAgreement {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/edit`} />
                    <Route exact render={(props: any) => <AddCustomerAgreement {...props} steps={[2, 3]} type={FormType.AddCustomerAgreementFromAgreement} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/add/customeragreement`} />
                    <Route exact render={(props: any) => <AddCustomerAgreement {...props} steps={[3]} type={FormType.Edit} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/customeragreement/:customerAgreementId/edit`} />
                    <Route exact render={(props: any) => <AddAgreementVersionPricelist {...props} steps={[2, 4]} type={FormType.Add} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/add/agreementversionpricelist`} />
                    <Route exact render={(props: any) => <AddAgreementVersionPricelist {...props} steps={[4]} type={FormType.EditAgreementVersionPriceListViaAgreement} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/${UrlConstants.AGREEMENT_VERSION_PRICE_LIST}/:agreementVersionPricelistId/edit`} />
                    <Route exact render={(props: any) => <AddAgreementVersionSpecialPrice {...props} steps={[1]} type={FormType.Add} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/add/agreementversionspecialprice`} />
                    <Route exact render={(props: any) => <AddAgreementVersionSpecialPrice {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.AGREEMENTS}/:agreementId/version/:agreementVersionId/agreementversionspecialprice/:agreementVersionSpecialPriceId/edit`} />
                    <Route exact path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.AGREEMENTS}/${props.match.params.agreementId}/${props.match.params.agreementVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={viewAgreementDetails} path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId`} />

                    <Route exact component={products} path={`/${UrlConstants.PRODUCTS}`} />
                    <Route exact render={(props: any) => <AddProduct {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.PRODUCTS}/add`} />
                    <Route exact render={(props: any) => <AddProduct {...props} steps={[1, 2]} type={FormType.Edit} />} path={`/${UrlConstants.PRODUCTS}/:productId/edit`} />
                    <Route exact render={(props: any) => <AddProductDynamics {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.PRODUCTS}/:productId/add/dynamics`} />
                    <Route exact render={(props: any) => <AddProductDynamics {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.PRODUCTS}/:productId/dynamics/:productDynamicsId/edit`} />
                    <Route exact render={(props: any) => <AddPricelistVersionProduct {...props} steps={[1, 3, 4]} type={FormType.Add} />}
                        path={`/${UrlConstants.PRODUCTS}/:productId/add/pricelistversionproduct`} />
                    <Route exact render={(props: any) => <AddPricelistVersionProduct {...props} steps={[4]} type={FormType.EditPriceListVersionProductViaProduct} />}
                        path={`/${UrlConstants.PRODUCTS}/${UrlConstants.PRICE_LIST_VERSION_PRODUCTS}/:id/edit`} />
                    <Route exact path={`/${UrlConstants.PRODUCTS}/:productId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.PRODUCTS}/${props.match.params.productId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={productDetails} path={`/${UrlConstants.PRODUCTS}/:productId`} />

                    <Route exact component={pricelists} path={`/${UrlConstants.PRICE_LISTS}`} />
                    <Route
                        exact
                        render={(props: any) => <AddPriceListVersion {...props} steps={[2, 1, 3, 5]} type={FormType.Add} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/add/version`}
                    />
                    <Route
                        exact
                        render={(props: any) => <AddPriceListVersion {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/version/:priceListVersionId/edit`}
                    />
                    <Route exact render={(props: any) => <AddPriceListVersion {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/version/:priceListVersionId/status`} />
                    <Route
                        exact
                        render={(props: any) => <AddPricelist {...props} steps={[1, 2]} type={FormType.Add} />}
                        path={`/${UrlConstants.PRICE_LISTS}/add`}
                    />
                    <Route exact render={(props: any) => <AddPricelist {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.PRICE_LISTS}/:priceListId/edit`} />
                    <Route exact render={(props: any) => <AddPriceListVersion {...props} steps={[5]} type={FormType.Clone} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/version/:priceListVersionId/clone`} />
                    <Route exact render={(props: any) => <AddPricelistVersionProduct {...props} steps={[2, 4]} type={FormType.Add} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/version/:priceListVersionId/add/pricelistversionproduct`} />
                    <Route exact render={(props: any) => <AddPricelistVersionProduct {...props} steps={[4]} type={FormType.EditPriceListVersionProductViaPriceList} />}
                        path={`/${UrlConstants.PRICE_LISTS}/${UrlConstants.PRICE_LIST_VERSION_PRODUCTS}/:id/edit`} />
                    <Route exact render={(props: any) => <AddAgreementVersionPricelist {...props} steps={[1, 3, 4]} type={FormType.Add} />}
                        path={`/${UrlConstants.PRICE_LISTS}/:priceListId/add/agreementversionpricelist`} />
                    <Route exact render={(props: any) => <AddAgreementVersionPricelist {...props} steps={[4]} type={FormType.EditAgreementVersionPriceListViaPriceList} />}
                        path={`/${UrlConstants.PRICE_LISTS}/${UrlConstants.AGREEMENT_VERSION_PRICE_LIST}/:agreementVersionPricelistId/edit`} />
                    <Route exact path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.PRICE_LISTS}/${props.match.params.priceListId}/${props.match.params.priceListVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={pricelistDetails} path={`/${UrlConstants.PRICE_LISTS}/:priceListId/:priceListVersionId`} />
                    <Route exact component={factorSetCancellation} path={`/${UrlConstants.FACTOR_SETS}/cancellation`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/cancellation/add`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellationLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellationLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/version/:factorSetVersionId/cancellationLine/:cancellationLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetCancellation {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/cancellation/${props.match.params.cancellationId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetCancellationDetails} path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId`} />

                    <Route exact component={factorSetHours} path={`/${UrlConstants.FACTOR_SETS}/hours`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/hours/add`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetHoursLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetHoursLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/version/:factorSetVersionId/hoursLine/:hoursLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetHours {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/hours/${props.match.params.hoursId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetHoursDetails} path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId`} />

                    <Route exact component={factorSetWeights} path={`/${UrlConstants.FACTOR_SETS}/weight`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/weight/add`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetWeightLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetWeightLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/version/:factorSetVersionId/weightLine/:weightLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetWeight {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/weight/${props.match.params.weightId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetWeightDetails} path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId`} />

                    <Route exact component={factorSetsPriority} path={`/${UrlConstants.FACTOR_SETS}/priority`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/priority/add`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetPriorityLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetPriorityLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/version/:factorSetVersionId/priorityLine/:priorityLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetPriority {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/priority/${props.match.params.priorityId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetPriorityDetails} path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId`} />

                    <Route exact component={factorSetDepartures} path={`/${UrlConstants.FACTOR_SETS}/departure`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/departure/add`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDepartureLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetDepartureLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/version/:factorSetVersionId/departureLine/:departureLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetDeparture {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/departure/${props.match.params.departureId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetDepartureDetails} path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId`} />

                    <Route exact component={FactorSetDangerousGoods} path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/add`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoodsLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoodsLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/version/:factorSetVersionId/dangerousgoodsLine/:dangerousGoodsLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetDangerousGoods {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${props.match.params.dangerousGoodId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetDangerousGoodsDetails} path={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/:dangerousGoodId/:factorSetVersionId`} />

                    <Route exact component={factorSetCalendar} path={`/${UrlConstants.FACTOR_SETS}/calendar`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[1, 2]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[4]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/version/:factorSetVersionId/status`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.FACTOR_SETS}/calendar/add`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/:factorSetVersionId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendarLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/version/:factorSetVersionId/add/line`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendarLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/version/:factorSetVersionId/calendarLine/:calendarLineId/edit`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[2, 3]} type={FormType.AddVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/add/version`} />
                    <Route exact render={(props: any) => <AddFactorSetCalendar {...props} steps={[2]} type={FormType.EditVersion} />}
                        path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/version/:factorSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/:factorSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.FACTOR_SETS}/calendar/${props.match.params.calendarId}/${props.match.params.factorSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={factorSetCalendarDetails} path={`/${UrlConstants.FACTOR_SETS}/calendar/:calendarId/:factorSetVersionId`} />

                    <Route exact render={(props: any) => <AddOrder {...props} steps={[2]} type={FormType.EditUnit} />} path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/edit`} />
                    <Route exact render={(props: any) => <AddOrderUnitService {...props} steps={[1, 2]} type={FormType.AddUnitService} />}
                        path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/orderunitservice/add`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[3]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/status`} />
                    <Route exact path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={orderUnitDetails} path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId`} />

                    <Route exact render={(props: any) => <AddOrderUnitServiceProduct {...props} steps={[1, 2, 3, 4]} type={FormType.AddUnitServiceProduct} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/orderunitserviceproduct/add`} />
                    <Route exact render={(props: any) => <AddOrderUnitService {...props} steps={[1, 2]} type={FormType.EditUnitService} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/edit`} />
                    <Route exact render={(props: any) => <AddOrderUnitService {...props} steps={[3]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/status`} />
                    <Route exact path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={orderUnitServiceDetails} path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId`} />

                    <Route exact render={(props: any) => <AddOrderUnitServiceProduct {...props} steps={[1, 2, 3, 4]} type={FormType.EditUnitServiceProduct} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/edit`} />
                    <Route exact render={(props: any) => <AddOrderUnitServiceProductSurcharge {...props} steps={[1]} type={FormType.AddUnitServiceProductSurcharge} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/surcharge/add`} />
                    <Route exact render={(props: any) => <AddOrderUnitServiceProduct {...props} steps={[5]} type={FormType.ChangeStatus} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/status`} />
                    <Route exact render={(props: any) => <AddOrderUnitServiceProduct {...props} steps={[6]} type={FormType.ChangeOverriddenPrice} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/overriddenPrice`} />
                    <Route exact path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${props.match.params.orderUnitServiceProductId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={orderUnitServiceProductDetails} path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId`} />

                    <Route exact render={(props: any) => <AddOrderUnitServiceProductSurcharge {...props} steps={[1]} type={FormType.EditUnitServiceProductSurcharge} />}
                        path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/:orderUnitServiceProductSurchargeId/edit`} />
                    <Route exact path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/:orderUnitServiceProductSurchargeId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${props.match.params.orderUnitServiceProductSurchargeId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={OrderUnitServiceProductSurchargeDetails} path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/:orderUnitServiceProductSurchargeId`} />

                    <Route exact component={gtsOrders} path={`/${UrlConstants.GTS_ORDERS}`} />
                    <Route exact render={(props: any) => <ImportGtsOrder {...props} steps={[1]} type={FormType.Import} />} path={`/${UrlConstants.GTS_ORDERS}/import`} />
                    <Route exact render={(props: any) => <AddGtsOrder {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.GTS_ORDERS}/:gtsOrderId/edit`} />
                    <Route exact path={`/${UrlConstants.GTS_ORDERS}/:gtsOrderId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.GTS_ORDERS}/${props.match.params.gtsOrderId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={gtsOrderDetails} path={`/${UrlConstants.GTS_ORDERS}/:gtsOrderId`} />

                    <Route exact render={(props: any) => <AddGtsOrderUnitDangerousGoods {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/dangerousgoods/:gtsDangerousGoodsId/edit`} />
                    <Route exact render={(props: any) => <AddGtsOrderUnitDepot {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/depot/:depotId/edit`} />
                    <Route exact render={(props: any) => <AddGtsOrderUnitService {...props} steps={[1]} type={FormType.EditUnitService} />}
                        path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/unitservice/:unitServiceId/edit`} />
                    <Route exact render={(props: any) => <AddGtsOrderUnit {...props} steps={[1, 2]} type={FormType.EditUnit} />}
                        path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/edit`} />
                    <Route exact path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={gtsOrderUnitDetails} path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId`} />

                    <Route exact render={(props: any) => <AddGtsOrderUnitService {...props} steps={[1]} type={FormType.EditUnitService} />}
                        path={`/${UrlConstants.GTS_UNIT_SERVICES}/:unitServiceId/edit`} />
                    <Route exact path={`/${UrlConstants.GTS_UNIT_SERVICES}/:unitServiceId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.GTS_UNIT_SERVICES}/${props.match.params.unitServiceId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={unitServiceDetails} path={`/${UrlConstants.GTS_UNIT_SERVICES}/:unitServiceId`} />

                    <Route exact render={(props: any) => <AddGtsOrderUnitDangerousGoods {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.UNIT_DANGEROUS_GOODS}/:gtsDangerousGoodsId/edit`} />
                    <Route exact path={`/${UrlConstants.UNIT_DANGEROUS_GOODS}/:gtsDangerousGoodsId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.UNIT_DANGEROUS_GOODS}/${props.match.params.gtsDangerousGoodsId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={unitDangerousGoodsDetails} path={`/${UrlConstants.UNIT_DANGEROUS_GOODS}/:gtsDangerousGoodsId`} />

                    <Route exact render={(props: any) => <AddGtsOrderUnitDepot {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.UNIT_DEPOT}/:depotId/edit`} />
                    <Route exact path={`/${UrlConstants.UNIT_DEPOT}/:depotId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.UNIT_DEPOT}/${props.match.params.depotId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={unitDepotDetails} path={`/${UrlConstants.UNIT_DEPOT}/:depotId`} />

                    <Route exact component={orders} path={`/${UrlConstants.ORDERS}`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.ORDERS}/add`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.ORDERS}/:orderId/edit`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[2]} type={FormType.EditUnit} />} path={`/${UrlConstants.ORDERS}/:orderId/orderunitid/:orderUnitId/edit`} />
                    <Route exact render={(props: any) => <AddOrder {...props} steps={[2]} type={FormType.AddUnit} />} path={`/${UrlConstants.ORDERS}/:orderId/orderunit/add`} />
                    <Route exact path={`/${UrlConstants.ORDERS}/:orderId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.ORDERS}/${props.match.params.orderId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={orderDetails} path={`/${UrlConstants.ORDERS}/:orderId`} />

                    <Route exact component={services} path={`/${UrlConstants.SERVICES}`} />
                    <Route exact render={(props: any) => <AddService {...props} steps={[1, 2]} type={FormType.Add} />} path={`/${UrlConstants.SERVICES}/add`} />
                    <Route exact render={(props: any) => <AddService {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.SERVICES}/:serviceId/edit`} />
                    <Route exact render={(props: any) => <AddService {...props} steps={[3]} type={FormType.ChangeStatus} />} path={`/${UrlConstants.SERVICES}/:serviceId/status`} />
                    <Route exact render={(props: any) => <AddService {...props} steps={[2]} type={FormType.ChangeServiceProducts} />}
                        path={`/${UrlConstants.SERVICES}/:serviceId/serviceproducts/edit`} />
                    <Route exact path={`/${UrlConstants.SERVICES}/:serviceId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.SERVICES}/${props.match.params.serviceId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={serviceDetails} path={`/${UrlConstants.SERVICES}/:serviceId`} />

                    <Route exact render={(props: any) => <AddAgreementVersionSpecialPrice {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/:agreementVersionSpecialPriceId/edit`} />
                    <Route exact path={`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/:agreementVersionSpecialPriceId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${props.match.params.agreementVersionSpecialPriceId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={agreementVersionSpecialPriceDetails} path={`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/:agreementVersionSpecialPriceId`} />

                    <Route exact render={(props: any) => <AddAgreementVersionDiscount {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/:agreementVersionTransitDiscountId/edit`} />
                    <Route exact path={`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/:agreementVersionTransitDiscountId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${props.match.params.agreementVersionTransitDiscountId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={AgreementVersionTransitDiscountDetails} path={`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/:agreementVersionTransitDiscountId`} />

                    <Route exact component={Invoices} path={`/${UrlConstants.INVOICES}`} />
                    <Route exact render={(props: any) => <AddInvoice {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.INVOICES}/:invoiceId/edit`} />
                    <Route exact render={(props: any) => <AddInvoiceLine {...props} steps={[1]} type={FormType.AddLine} />}
                        path={`/${UrlConstants.INVOICES}/:invoiceId/add/line`} />
                    <Route exact render={(props: any) => <AddInvoiceLine {...props} steps={[1]} type={FormType.EditLine} />}
                        path={`/${UrlConstants.INVOICE_LINES}/:invoiceLineId/edit`} />

                    <Route exact path={`/${UrlConstants.INVOICES}/:invoiceId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={InvoiceDetails} path={`/${UrlConstants.INVOICES}/:invoiceId`} />

                    <Route exact path={`/${UrlConstants.INVOICE_LINES}/:invoiceLineId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.INVOICE_LINES}/${props.match.params.invoiceLineId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={InvoiceLineDetails} path={`/${UrlConstants.INVOICE_LINES}/:invoiceLineId`} />

                    <Route exact component={Simulations} path={`/${UrlConstants.SIMULATIONS}`} />
                    <Route exact path={`/${UrlConstants.SIMULATIONS}/:simulationId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.SIMULATIONS}/${props.match.params.simulationId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={InvoiceDetails} path={`/${UrlConstants.SIMULATIONS}/:simulationId`} />

                    {/* <Route exact component={Simulations} path={`/${UrlConstants.SIMULATIONS}`} />
                    <Route exact path={`/${UrlConstants.SIMULATIONS}/:simulationId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.SIMULATIONS}/${props.match.params.simulationId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={InvoiceDetails} path={`/${UrlConstants.SIMULATIONS}/:simulationId`} /> */}

                    <Route exact component={SurchargeRates} path={`/${UrlConstants.SURCHARGE_RATES}`} />
                    <Route exact render={(props: any) => <AddSurchargeRate {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.SURCHARGE_RATES}/add`} />
                    <Route exact render={(props: any) => <AddSurchargeRate {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.SURCHARGE_RATES}/:surchargeRateId/edit`} />
                    <Route exact path={`/${UrlConstants.SURCHARGE_RATES}/:surchargeRateId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.SURCHARGE_RATES}/${props.match.params.surchargeRateId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={SurchargeRateDetails} path={`/${UrlConstants.SURCHARGE_RATES}/:surchargeRateId`} />

                    <Route exact component={Users} path={`/${UrlConstants.USERS}`} />
                    <Route exact render={(props: any) => <AddUser {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.USERS}/add`} />
                    <Route exact render={(props: any) => <AddUser {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.USERS}/:userId/edit`} />
                    <Route exact path={`/${UrlConstants.USERS}/:userId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.USERS}/${props.match.params.userId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={UserDetails} path={`/${UrlConstants.USERS}/:userId`} />

                    <Route exact component={Terminals} path={`/${UrlConstants.TERMINALS}`} />
                    <Route exact render={(props: any) => <AddTerminal {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.TERMINALS}/add`} />
                    <Route exact render={(props: any) => <AddTerminal {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.TERMINALS}/:terminalId/edit`} />
                    <Route exact path={`/${UrlConstants.TERMINALS}/:terminalId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.TERMINALS}/${props.match.params.terminalId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={TerminalDetails} path={`/${UrlConstants.TERMINALS}/:terminalId`} />

                    <Route exact component={ProductArenas} path={`/${UrlConstants.PRODUCT_ARENAS}`} />
                    <Route exact render={(props: any) => <AddProductArena {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.PRODUCT_ARENAS}/add`} />
                    <Route exact render={(props: any) => <AddProductArena {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.PRODUCT_ARENAS}/:productArenaId/edit`} />
                    <Route exact path={`/${UrlConstants.PRODUCT_ARENAS}/:productArenaId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.PRODUCT_ARENAS}/${props.match.params.productArenaId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={ProductArenaDetails} path={`/${UrlConstants.PRODUCT_ARENAS}/:productArenaId`} />
                    <Route exact render={(props: any) => <AddServiceProduct {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.SERVICE_PRODUCTS}/:serviceProductId/edit`} />
                    <Route exact component={OrdersNotInvoiced} path={`/${UrlConstants.REPORTS_ORDERS_NOT_INVOICED}`} />

                    <Route exact component={ResponsibilitiesCenter} path={`/${UrlConstants.RESPONSIBILITIES_CENTER}`} />
                    <Route exact render={(props: any) => <AddResponsibilityCenter {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.RESPONSIBILITIES_CENTER}/add`} />
                    <Route exact render={(props: any) => <AddResponsibilityCenter {...props} steps={[1]} type={FormType.Edit} />}
                        path={`/${UrlConstants.RESPONSIBILITIES_CENTER}/:responsibilityCenterId/edit`} />
                    <Route exact path={`/${UrlConstants.RESPONSIBILITIES_CENTER}/:responsibilityCenterId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.RESPONSIBILITIES_CENTER}/${props.match.params.responsibilityCenterId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={ResponsibiliyCenterDetails} path={`/${UrlConstants.RESPONSIBILITIES_CENTER}/:responsibilityCenterId`} />
                    <Route exact render={(props: any) => <AddServiceProduct {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.SERVICE_PRODUCTS}/:serviceProductId/edit`} />
                    <Route exact path={`/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/:printAgreementVersionPeriodId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/${props.match.params.printAgreementVersionPeriodId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={PrintAgreementVersionPeriodDetails} path={`/${UrlConstants.PRINT_AGREEMENT_VERSION_PERIOD_LINES}/:printAgreementVersionPeriodId`} />
                    <Route component={OrdersNotInvoiced} path={`/${UrlConstants.ORDER_NOT_YET_INVOICED}`} />
                    <Route component={OrdersNotYetInvoicedFullReport} path={`/${UrlConstants.ORDER_NOT_YET_INVOICED_FULL_REPORT}/:customerId/:month/:year`} />
                    <Route component={Reports} path={`/${UrlConstants.REPORTS}`} />
                    <Route exact component={ProvisionCSV} path={`/${UrlConstants.PROVISIONS}`} />
                    <Route exact component={KickbackAgreementCSV} path={`/${UrlConstants.KICKBACK_AGREEMENT_POSTEN}`} />
                    <Route exact component={ReportHeaderPivoted} path={`/${UrlConstants.REPORT_HEADER_PIVOTED}`} />
                    <Route exact component={ReportHeader} path={`/${UrlConstants.REPORT_HEADER}/:month/:year`} />
                    <Route exact component={ReportLine} path={`/${UrlConstants.REPORT_LINES}/:pipelineGtsOrdersHeaderId`} />
                    {/* <Route exact path={`/${UrlConstants.REPORT_LINE}/:reportLineId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.REPORT_LINE}/${props.match.params.reportLineId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    /> */}
                    <Route exact component={ReportLineDetails} path={`/${UrlConstants.REPORT_LINE}/:reportLineId`} />
                    <Route exact component={ReportCustomerTermsComponent} path={`/${UrlConstants.REPORT_CUSTOMER_TERMS}`} />
                    <Route exact component={CapriScheduledJob} path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}`} />
                    <Route exact render={(props: any) => <AddCapriScheduledJob {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/add`} />
                    <Route exact render={(props: any) =>
                        <AddCapriScheduledJob {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/:capriScheduledJobId/edit`} />
                    <Route exact path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/:capriScheduledJobId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/${props.match.params.capriScheduledJobId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={CapriScheduledJobDetails} path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/:capriScheduledJobId`} />

                    <Route exact component={CancellationRoleSet} path={`/${UrlConstants.CANCELLATION_RULE_SET}`} />
                    <Route exact render={(props: any) => <AddCancellationRuleSet {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CANCELLATION_RULE_SET}/add`} />
                    <Route exact render={(props: any) =>
                        <AddCancellationRuleSet {...props} steps={[1]} type={FormType.Edit} />} path={`/${UrlConstants.CANCELLATION_RULE_SET}/:cancellationRuleSetId/edit`} />
                    <Route exact path={`/${UrlConstants.CANCELLATION_RULE_SET}/:cancellationRuleSetId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.CANCELLATION_RULE_SET}/${props.match.params.cancellationRuleSetId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={CancellationRuleSetDetails} path={`/${UrlConstants.CANCELLATION_RULE_SET}/:cancellationRuleSetId`} />
                    <Route exact render={(props: any) =>
                        <AddCancellationRuleSetVersion {...props} steps={[1]} type={FormType.Add} />} path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetId/add`} />
                    <Route exact render={(props: any) =>
                        <AddCancellationRuleSetVersion {...props} steps={[1]} type={FormType.Edit} />}
                    path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetVersionId/edit`} />
                    <Route exact path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetVersionId`}
                        render={props => (
                            <Redirect to={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${props.match.params.cancellationRuleSetVersionId}/${UrlConstants.PROPERTIES}`} />
                        )}
                    />
                    <Route component={CancellationRuleSetVersionDetails} path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetVersionId`} />
                    <Route component={CancellationRuleDetails} path={`/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/:cancellationRuleId`} />
                </Switch>
            </Root>
        </HashRouter >
    </ThemeProvider >
);

export default hot(AppRouter);