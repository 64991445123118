import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import Pagination from '../../../interfaces/common/pagination';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import LanguageUtils from '../../../utils/LanguageUtils';
import { RootState } from '../../../setup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageUtils from '../../../utils/pageUtils';
import MainLayout from '../../common/widgets/mainLayout';
import UrlConstants from '../../../constants/UrlConstants';
import { fetchCancellationRuleSets, getCancellationRuleSets, isLoadingCancellationRuleSets, resetCancellationRuleSet } from '../../../reducers/cancellationRuleSetReducer';
import CancellationRuleSetFilter from '../../../interfaces/filters/cancellationRuleSetFilter';
import CancellationRuleSet from '../../../interfaces/output/cancellationRuleSet';
import CancellationRuleSetList from '../cancellationRuleSet/cancellationRuleSetList';
import CancellationRuleSetFilterComponent from './cancellationRuleSetFilter';

interface IScheduledJobProps {
    classes: any;
    isLoading: boolean;
    onChange: () => void;
    resetCancellationRuleSets: () => void;
    fetchCancellationRuleSets: any;
    cancellationRuleSets: Pagination<CancellationRuleSet>;
}

interface ICapriScheduledJobsState {
    filters: CancellationRuleSetFilter;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    cancellationRuleSet: LanguageUtils.createMessage('Cancellation rule set'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCancellationRuleSets: (paging: Paging, filters: CancellationRuleSetFilter) => dispatch(fetchCancellationRuleSets({
        paging,
        filters
    })),
    resetCancellationRuleSets: () => dispatch(resetCancellationRuleSet())
});

const mapStoreToProps = (store: RootState) => ({
    isLoading: isLoadingCancellationRuleSets(store),
    cancellationRuleSets: getCancellationRuleSets(store)
});

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class CapriSchduledJobComponent extends Component<IScheduledJobProps, ICapriScheduledJobsState> {
    capriSchduledJobListRef: any;
    constructor(props: IScheduledJobProps) {
        super(props);

        this.state = {
            filters: {} as CancellationRuleSetFilter,
            menuItem: {
                id: 1,
                name: messages.cancellationRuleSet,
                icon: <FontAwesomeIcon icon="info" size="1x" transform="grow-7" />,
                isSelected: false
            },
            paging: PageUtils.getDefaultPaging([{
                field: 'name',
                sort: 'asc'
            } as GridSortItem]),
            sortModel: [{
                field: 'name',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
            }
        };
    }

    componentDidMount() {
        const { fetchCancellationRuleSets } = this.props;
        const { paging, filters } = this.state;

        fetchCancellationRuleSets(paging, filters);
    }

    componentWillUnmount() {
        const { resetCancellationRuleSets } = this.props;
        resetCancellationRuleSets();
    }

    applyFilters = () => {
        const { fetchCancellationRuleSets } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchCancellationRuleSets(newPagination, filters);

        if(this.capriSchduledJobListRef !== null && this.capriSchduledJobListRef !== undefined &&
            this.capriSchduledJobListRef.current !== null && this.capriSchduledJobListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as CancellationRuleSetFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchCancellationRuleSets } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'name',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
        } as CancellationRuleSetFilter;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchCancellationRuleSets(newPagination, newFilters);

        if(this.capriSchduledJobListRef !== null && this.capriSchduledJobListRef !== undefined &&
            this.capriSchduledJobListRef.current !== null && this.capriSchduledJobListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchCancellationRuleSets } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchCancellationRuleSets(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoading, cancellationRuleSets, fetchCancellationRuleSets } = this.props;
        const { filters, menuItem, paging, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <CancellationRuleSetFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.CANCELLATION_RULE_SET}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <CancellationRuleSetList
                                    rows={cancellationRuleSets} isLoading={isLoading}
                                    ref={this.capriSchduledJobListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchCancellationRuleSets(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CapriSchduledJobComponent));