import { List } from 'immutable';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationUtils from '../../../utils/validationUtils';
import ValidationModel from '../../../utils/validationModel';
import { ensure } from '../../../utils/arrayUtils';
import CancellationRuleSetVersion from '../../../interfaces/output/cancellationRuleSetVersion';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range'),
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class CancellationRuleSetVersionStep1Validation extends ValidationUtils {
    static validateRequiredDate(inputDate: Date | undefined): boolean {

        return !!inputDate;
    }

    static validateInputDateStart(cancellationRuleSetVersion: CancellationRuleSetVersion | undefined): boolean {
        const dateStart = cancellationRuleSetVersion?.validFromDate;
        const dateTo = cancellationRuleSetVersion?.validToDate;

        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(cancellationRuleSetVersion: CancellationRuleSetVersion | undefined): boolean {
        const dateStart = cancellationRuleSetVersion?.validFromDate;
        const dateTo = cancellationRuleSetVersion?.validToDate;

        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateCancellationRuleSetVersionForm(cancellationRuleSetVersion: CancellationRuleSetVersion | undefined): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { validFromDate,
            validToDate
        } = cancellationRuleSetVersion as CancellationRuleSetVersion;

        const requiredFields =
            this.validateRequiredDate(validFromDate) &&
            this.validateRequiredDate(validToDate);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = cancellationRuleSetVersion?.validToDate;
        const validDates = endDate ? this.validateInputDateStart(cancellationRuleSetVersion) &&
            this.validateInputDateEnd(cancellationRuleSetVersion) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const formatedDate = this.validateDateFormat(validFromDate) &&
            this.validateDateFormat(validToDate);

        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }
}
