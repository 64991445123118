import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import Pagination from '../interfaces/common/pagination';
import CancellationRuleSetFilter from '../interfaces/filters/cancellationRuleSetFilter';
import CancellationRuleSet from '../interfaces/output/cancellationRuleSet';
import CancellationRuleFilter from '../interfaces/filters/cancellationRuleFilter';
import CancellationRule from '../interfaces/output/cancellationRule';
import CancellationRuleSetVersionFilter from '../interfaces/filters/cancellationRuleSetVersionFilter';
import CancellationRuleSetVersion from '../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleSetTypeFilter from '../interfaces/filters/cancellationRuleSetTypeFilter';
import CancellationRuleSetType from '../interfaces/output/cancellationRuleSetType';

export default class CancellationRuleSetApi {

    static fetchCancellationRuleSet = async(paging: Paging, filters?: CancellationRuleSetFilter): Promise<Pagination<CancellationRuleSet>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/cancellationruleset');

        const { data } = await http.get<Pagination<CancellationRuleSet>>(url);

        return data;
    };

    static fetchCancellationRuleSetById = async(cancellationRuleSetId: string): Promise<CancellationRuleSet> => {
        const { data } = await http.get<CancellationRuleSet>(`/cancellationruleset/${cancellationRuleSetId}`);

        return data;
    };

    static createCancellationRuleSet = async(cancellationRuleSet: CancellationRuleSet): Promise<CancellationRuleSet> => {
        const newCancellationRuleSet = {
            cancellationRuleSetTypeId: cancellationRuleSet?.cancellationRuleSetType?.id,
            name: cancellationRuleSet?.name,
            nameNo: cancellationRuleSet?.nameNo
        };

        const { data } = await http.post<any, CancellationRuleSet>('cancellationruleset', newCancellationRuleSet);

        return data;
    };

    static updateCancellationRuleSet = async(cancellationRuleSet: CancellationRuleSet): Promise<CancellationRuleSet> => {
        const newCancellationRuleSet = {
            cancellationRuleSetTypeId: cancellationRuleSet?.cancellationRuleSetType?.id,
            name: cancellationRuleSet?.name,
            nameNo: cancellationRuleSet?.nameNo
        };

        const { data } = await http.put<any, CancellationRuleSet>(`/cancellationruleset/${cancellationRuleSet?.id}`, newCancellationRuleSet);

        return data;
    };

    static deleteCancellationRuleSetById = async(cancellationRuleSetId: string): Promise<CancellationRuleSet> => {
        const { data } = await http.delete<CancellationRuleSet>(`/cancellationruleset/${cancellationRuleSetId}`);

        return data;
    };

    static fetchCancellationRuleSetVersions = async(paging: Paging, filters?: CancellationRuleSetVersionFilter): Promise<Pagination<CancellationRuleSetVersion>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/cancellationrulesetversion');

        const { data } = await http.get<Pagination<CancellationRuleSetVersion>>(url);

        return data;
    };

    static fetchCancellationRuleSetVersionById = async(cancellationRuleSetVersionId: string): Promise<CancellationRuleSetVersion> => {
        const { data } = await http.get<CancellationRuleSetVersion>(`/cancellationrulesetversion/${cancellationRuleSetVersionId}`);

        return data;
    };

    static createCancellationRuleSetVersion = async(cancellationRuleSetVersion: CancellationRuleSetVersion): Promise<CancellationRuleSetVersion> => {
        const newCancellationRuleSetVersion = {
            cancellationRuleSetId: cancellationRuleSetVersion?.cancellationRuleSetId,
            validFromDate: cancellationRuleSetVersion?.validFromDate,
            validToDate: cancellationRuleSetVersion?.validToDate
        };

        const { data } = await http.post<any, CancellationRuleSet>('/cancellationrulesetversion', newCancellationRuleSetVersion);

        return data;
    };

    static updateCancellationRuleSetVersion = async(cancellationRuleSetVersion: CancellationRuleSetVersion): Promise<CancellationRuleSetVersion> => {
        const newCancellationRuleSetVersion = {
            cancellationRuleSetId: cancellationRuleSetVersion?.cancellationRuleSet?.id,
            validFromDate: cancellationRuleSetVersion?.validFromDate,
            validToDate: cancellationRuleSetVersion?.validToDate
        };

        const { data } = await http.put<any, CancellationRuleSet>(`/cancellationrulesetversion/${cancellationRuleSetVersion?.id}`, newCancellationRuleSetVersion);

        return data;
    };

    static deleteCancellationRuleSetVersionById = async(cancellationRuleSetVersionId: string): Promise<CancellationRuleSetVersion> => {
        const { data } = await http.delete<CancellationRuleSet>(`/cancellationruleset/${cancellationRuleSetVersionId}`);

        return data;
    };

    static fetchCancellationRule = async(paging: Paging, filters?: CancellationRuleFilter): Promise<Pagination<CancellationRule>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/cancellationrule');

        const { data } = await http.get<Pagination<CancellationRule>>(url);

        return data;
    };

    static fetchCancellationRuleSetType = async(paging: Paging, filters?: CancellationRuleSetTypeFilter): Promise<Pagination<CancellationRuleSetType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/cancellationrulesettype');

        const { data } = await http.get<Pagination<CancellationRuleSetType>>(url);

        return data;
    };

    static fetchCancellationRuleById = async(cancellationRuleId: string): Promise<CancellationRule> => {
        const { data } = await http.get<CancellationRule>(`/cancellationrule/${cancellationRuleId}`);

        return data;
    };
    static createCancellationRule = async(cancellationRule: CancellationRule): Promise<CancellationRule> => {
        const newCancellationRule = {
            cancellationType: cancellationRule?.cancellationType
            // validFromDate: cancellationRule?.validFromDate,
            // validToDate: cancellationRule?.validToDate
        };

        const { data } = await http.post<any, CancellationRule>('cancellationrule', newCancellationRule);

        return data;
    };

    static updateCancellationRule = async(cancellationRule: CancellationRule): Promise<CancellationRule> => {
        const newCancellationRule = {
            // cancellationRuleSetId: cancellationRule?.cancellationRuleSetId,
            // validFromDate: cancellationRule?.validFromDate,
            // validToDate: cancellationRule?.validToDate
        };

        const { data } = await http.put<any, CancellationRule>(`/cancellationrule/${cancellationRule?.id}`, newCancellationRule);

        return data;
    };

    static deleteCancellationRuleById = async(cancellationRuleId: string): Promise<CancellationRule> => {
        const { data } = await http.delete<CancellationRule>(`/cancellationrule/${cancellationRuleId}`);

        return data;
    };

}
