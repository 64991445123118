import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import {
    isUpdatingCancellationRuleSetVersion,
    isCreatingCancellationRuleSetVersion,
    isLoadingCancellationRuleSetVersionById
} from '../../../reducers/cancellationRuleSetReducer';
import { useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import Loader from '../../common/widgets/loader';
import { FormControl, Grid } from '@mui/material';
import CancellationRuleSetVersion from '../../../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleSetVersionStep1Validation from '../validations/cancellationRuleSetVersionStep1Validation';
import GenericDatePicker from '../../common/widgets/genericDatePicker';

interface ICancellationRuleSetVersionStep1Props {
    cancellationRuleSetVersion: CancellationRuleSetVersion;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    VALIDATE_FROM_DATE: 'validFromDate',
    VALIDATE_TO_DATE: 'validToDate'

};

const messages = {
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to')
};

const CancellationRuleSetVersionFormStep1 = forwardRef((props: ICancellationRuleSetVersionStep1Props, ref: any): JSX.Element => {
    const { cancellationRuleSetVersion, onChange, showValidationError } = props;
    const isLoadingObjects = [useSelector(isUpdatingCancellationRuleSetVersion), useSelector(isCreatingCancellationRuleSetVersion), useSelector(isLoadingCancellationRuleSetVersionById)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.VALIDATE_FROM_DATE: {
                isValid = CancellationRuleSetVersionStep1Validation.validateRequiredDate(cancellationRuleSetVersion.validFromDate) &&
                    CancellationRuleSetVersionStep1Validation.validateDateFormat(cancellationRuleSetVersion.validFromDate) &&
                    CancellationRuleSetVersionStep1Validation.validateInputDateStart(cancellationRuleSetVersion);
                break;
            }
            case ATTRIBUTES.VALIDATE_TO_DATE: {
                isValid = CancellationRuleSetVersionStep1Validation.validateRequiredDate(cancellationRuleSetVersion.validToDate) &&
                    CancellationRuleSetVersionStep1Validation.validateDateFormat(cancellationRuleSetVersion.validFromDate) &&
                    CancellationRuleSetVersionStep1Validation.validateInputDateEnd(cancellationRuleSetVersion);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFrom}
                            value={cancellationRuleSetVersion.validFromDate}
                            onChange={onChange}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALIDATE_FROM_DATE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validTo}
                            value={cancellationRuleSetVersion.validToDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALIDATE_TO_DATE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default CancellationRuleSetVersionFormStep1;
