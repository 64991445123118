import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import CancellationRule from '../../interfaces/output/cancellationRule';

interface IViewCancellationRuleProps {
    classes?: any;
    theme?: Theme;
    cancellationRule: CancellationRule;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Cancellation rule details'),
    priorityRank: LanguageUtils.createMessage('Priority rank'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    loadingWeekday: LanguageUtils.createMessage('Loading weekday'),
    fromLoadingMonthDay: LanguageUtils.createMessage('From loading month day'),
    toLoadingMonthDay: LanguageUtils.createMessage('To loading month day'),
    loadingTrainNumber: LanguageUtils.createMessage('Loading train number'),
    fromLoadingHours: LanguageUtils.createMessage('From loading hours'),
    toLoadingHours: LanguageUtils.createMessage('To loading hours'),
    departureType: LanguageUtils.createMessage('Departure type'),
    cancellationMinutesBeforeLoading: LanguageUtils.createMessage('Cancellation minutes befor loading'),
    fromDaysBeforeLoadingDate: LanguageUtils.createMessage('From days before loading date'),
    fromHourLimit: LanguageUtils.createMessage('From hour limit'),
    toDaysBeforeLoadingDate: LanguageUtils.createMessage('To days before loading date'),
    toHourLimit: LanguageUtils.createMessage('To hour limit'),
    cancellationType: LanguageUtils.createMessage('Cancellation type')
};

export default function ViewCancellationRule(props: IViewCancellationRuleProps): JSX.Element {
    const classes = styles();
    const { cancellationRule } = props;

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityRank} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.priorityRank || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.fromTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.toTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.unitType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.priorityType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.loadingWeekday} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.loadingWeekday || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromLoadingMonthDay} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.fromLoadingMonthDay || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toLoadingMonthDay} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.toLoadingMonthDay || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.loadingTrainNumber} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.loadingTrainNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromLoadingHours} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.fromLoadingHours || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toLoadingHours} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.toLoadingHours || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.departureType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.cancellationMinutesBeforeLoading} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.cancellationMinutesBeforeLoading || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromDaysBeforeLoadingDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.fromDaysBeforeLoadingDate || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromHourLimit} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.fromHourLimit || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toDaysBeforeLoadingDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.toDaysBeforeLoadingDate || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toHourLimit} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.toHourLimit || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.cancellationType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{cancellationRule?.cancellationType?.name || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}