import React, { useEffect } from 'react';
import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import OrdersNotYetInvoicedFilters from '../../../interfaces/filters/orderNotYetInvoicedFilters';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchUnitGroups } from '../../../reducers/unitGroupReducer';
import PageUtils from '../../../utils/pageUtils';
import { fetchCustomers, getCustomers, isLoadingCustomers, resetCustomers } from '../../../reducers/customerReducer';
import CustomerFilters from '../../../interfaces/output/filters';
import Customer from '../../../interfaces/output/customer';
import Currency from '../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../reducers/currencyReducer';
import OUSStatus from '../../../interfaces/output/ousStatus';
import { fetchOUSPStatuses, getOUSPStatuses } from '../../../reducers/ouspStatusReducer';
// import { DatePicker } from '@mui/lab';
interface IFilterProps {
    filters: OrdersNotYetInvoicedFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    customerName: LanguageUtils.createMessage('Customer Name'),
    customer: LanguageUtils.createMessage('Customer'),
    departureYear: LanguageUtils.createMessage('Departure year'),
    departureMonth: LanguageUtils.createMessage('Departure month'),
    serviceGroup: LanguageUtils.createMessage('Service group'),
    ousStatus: LanguageUtils.createMessage('Ous status'),
    currency: LanguageUtils.createMessage('Currency'),
    startDate: LanguageUtils.createMessage('from date'),
    endDate: LanguageUtils.createMessage('to date'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function OrdersNotYetInvoicedFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const [inputValue, setInputValue] = React.useState('');
    const paging = PageUtils.getMaxPaging();
    const customers = useSelector(getCustomers).content;
    const currencies = useSelector(getCurrencies).content;
    const ousStatuses = useSelector(getOUSPStatuses).content;
    const [customerValue, setCustomerValue] = React.useState<any>(null);
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const customerFilters = {
        name: inputValue
    } as CustomerFilters;
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
        // dispatch(fetchProductArenaTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchOUSPStatuses({ paging }));
    }, [dispatch]);

    const setDefaultStateOrdersNotYetInvoiced = () => {
        setInputValue('');
        onChangeCustomer('customerId', null);
        dispatch(resetCustomers());
        setDefaultState();
    };

    const onChangeCustomer = (attribute: string, value: any) => {

        onChange(attribute, value?.id);
        setCustomerValue(value);
    };

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <Autocomplete
                            value={customerValue}
                            onChange={(e, newValue: any) => {
                                onChangeCustomer('customerId', newValue);
                            }}
                            id="controllable-states-demo"
                            getOptionLabel={(option: Customer) => option.name}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue: any, reason) => {
                                setInputValue(newInputValue);
                                if(newInputValue.length > 2) {
                                    dispatch(resetCustomers());
                                    dispatch(fetchCustomers({
                                        paging,
                                        filters: {
                                            ...customerFilters,
                                            name: newInputValue
                                        },
                                        throttle: true
                                    }));
                                }
                                if(reason === 'clear' && event.type === 'click') {
                                    dispatch(resetCustomers());
                                }
                            }}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="departureYear"
                                label={messages.departureYear}
                                onChange={onChange}
                                value={filters.departureYear}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="departureMonth"
                                label={messages.departureMonth}
                                onChange={onChange}
                                value={filters.departureMonth}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="serviceGroupNameNo"
                                label={messages.serviceGroup}
                                onChange={onChange}
                                value={filters.serviceGroupNameNo}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericAutocomplete<OUSStatus>
                                options={ousStatuses}
                                value={filters.ousStatusName}
                                onChange={(obj: OUSStatus | null) => onChange('ousStatusName', obj?.name)}
                                placeholder={messages.ousStatus}
                                compareFn={(o: OUSStatus) => o.name === filters.ousStatusName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="fromDepartureDate"
                            label={messages.startDate}
                            value={filters?.fromDepartureDate}
                            onChange={onChange}
                            type="start"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="toDepartureDate"
                            label={messages.endDate}
                            value={filters?.toDepartureDate}
                            onChange={onChange}
                            type="end"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={filters.currencyCode}
                            onChange={(obj: Currency | null) => onChange('currencyCode', obj?.code)}
                            placeholder={messages.currency}
                            compareFn={(o: Currency) => o.code === filters.currencyCode}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultStateOrdersNotYetInvoiced}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}