import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import CancellationRule from '../../interfaces/output/cancellationRule';
import ViewCancellationRule from './viewCancellationRule';
import {
    deleteCancellationRule, fetchCancellationRuleById, getCancellationRule,
    isDeletingCancellationRule, isLoadingCancellationRuleById, isUpdatingCancellationRule,
    resetCancellationRule, updateCancellationRule
} from '../../reducers/cancellationRuleSetReducer';

interface ICancellationRuleDetailsProps {
    classes: any;
    theme: Theme;
    fetchCancellationRuleById: any;
    cancellationRule: CancellationRule;
    match: any;
    history: any;
    location: any;
    resetCancellationRule: () => void;
    isLoadingById: boolean;
    isDeleting: boolean;
    updateCancellatioRule: any;
    deleteCancellationRule: any;
    isLoading: any;
}

interface ICancellationRuleDetailsState {
    cancellationRule: CancellationRule;
    menuItems: ImmutableList<IMenuItem>;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCancellationRuleById: (cancellationRuleId: number) => dispatch(fetchCancellationRuleById(cancellationRuleId)),
    resetCancellationRule: () => dispatch(resetCancellationRule()),
    updateCancellationRule: (cancellationRule: CancellationRule) => dispatch(updateCancellationRule(cancellationRule)),
    deleteCancellationRule: (cancellationRuleId: number) => dispatch(deleteCancellationRule(cancellationRuleId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('Cancellation Rule'),
    properties: LanguageUtils.createMessage('Properties')
};

const mapStoreToProps = (store: RootState) => {
    return {
        cancellationRule: getCancellationRule(store),
        isLoadingById: isLoadingCancellationRuleById(store),
        isUpdating: isUpdatingCancellationRule(store),
        isDeleting: isDeletingCancellationRule(store),
        isLoading: isLoadingCancellationRuleById(store) || isDeletingCancellationRule(store) || isUpdatingCancellationRule(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class CancellationRuleDetails extends Component<ICancellationRuleDetailsProps, ICancellationRuleDetailsState> {

    capriSchduledJobLogListRef: any;
    constructor(props: ICancellationRuleDetailsProps) {
        super(props);

        this.state = {
            cancellationRule: {} as CancellationRule,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${props.match.params.cancellationRuleId}`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${props.match.params.cancellationRuleId}`, props.location.pathname)
            }
            ])
        };
    }

    componentDidMount() {
        const { fetchCancellationRuleById } = this.props;
        const { cancellationRuleId } = this.props.match.params;

        fetchCancellationRuleById(cancellationRuleId);
    }

    componentDidUpdate(prevProps: ICancellationRuleDetailsProps) {
        const { cancellationRule, isDeleting, history } = this.props;

        if(cancellationRule !== prevProps.cancellationRule) {
            this.setState({ cancellationRule });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems
            });
        }
        if(!isDeleting && prevProps.isDeleting) {
            history.push(`/${UrlConstants.CANCELLATION_RULE_SET}`);
        }
    }

    componentWillUnmount() {
        const { resetCancellationRule } = this.props;
        resetCancellationRule();
    }
    deleteCancellationRule = () => {
        const { cancellationRuleId } = this.props.match.params;
        const { deleteCancellationRule } = this.props;

        deleteCancellationRule(cancellationRuleId);
    }
    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { cancellationRuleId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.CANCELLATION_RULE}/${cancellationRuleId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deleteCancellationRule
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, isLoadingById, isLoading } = this.props;
        const { cancellationRule, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoading}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.ProductArena}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: cancellationRule?.id,
                    icon: <FontAwesomeIcon icon="calendar-alt" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/:cancellationRuleId`}>
                            <Loader isLoading={isLoadingById}>
                                <ViewCancellationRule cancellationRule={cancellationRule} />
                            </Loader>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CancellationRuleDetails));