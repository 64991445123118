/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable require-yield */
// import { fork, take, cancel, all } from 'redux-saga/effects';
import customerSaga from '../sagas/customerSaga';
import { all } from 'redux-saga/effects';
import countrySaga from '../sagas/countrySaga';
import sellerSaga from '../sagas/sellerSaga';
import statusSaga from '../sagas/statusSaga';
import segmentSaga from '../sagas/segmentSaga';
import customerCorporateSaga from '../sagas/customerCorporateSaga';
import paymentDeadlineSaga from '../sagas/paymentDeadlineSaga';
import invoiceFrequencySaga from '../sagas/invoiceFrequencySaga';
import invoiceSeparationSaga from '../sagas/invoiceSeparationSaga';
import agreementSaga from '../sagas/agreementSaga';
import unitGroupSaga from '../sagas/unitGroupSaga';
import customerAgreementSaga from '../sagas/customerAgreementSaga';
import customerGroupSaga from '../sagas/customerGroupSaga';
import productArenaSaga from '../sagas/productArenaSaga';
import agreementVersionSaga from '../sagas/agreementVersionSaga';
import agreementVersionStatusSaga from '../sagas/agreementVersionStatusSaga';
import productSaga from '../sagas/productSaga';
import productTypesSaga from '../sagas/productTypesSaga';
import terminalSaga from '../sagas/terminalSaga';
import currencySaga from '../sagas/currencySaga';
import pricelistTypeSaga from '../sagas/pricelistTypeSaga';
import unitSubgroupsSaga from '../sagas/unitSubgroupsSaga';
import pricelistVersionSaga from '../sagas/pricelistVersionSaga';
import pricelistSaga from '../sagas/pricelistSaga';
import pricelistVersionStatusSaga from '../sagas/pricelistVersionStatusSaga';
import vatSaga from '../sagas/vatSaga';
import dynamicsSaga from '../sagas/dynamicsSaga';
import agreementVersionPriceListSaga from '../sagas/agreementVersionPriceListSaga';
import factorSetWeightSaga from '../sagas/factorSetWeightSaga';
import factorSetDepartureSaga from '../sagas/factorSetDepartureSaga';
import factorSetPrioritySaga from '../sagas/factorSetPrioritySaga';
import gtsSaga from '../sagas/gtsSaga';
import pricelistVersionProductSaga from '../sagas/pricelistVersionProductSaga';
import transitDiscountSaga from '../sagas/transitDiscountSaga';
import gtsOrderSaga from '../sagas/gtsOrderSaga';
import unitTypeSaga from '../sagas/unitTypeSaga';
import gtsOrderUnitSaga from '../sagas/gtsOrderUnitSaga';
import gtsOrderUnitDepotLinesSaga from '../sagas/gtsOrderUnitDepotLinesSaga';
import dangerousGoodsSaga from '../sagas/dangerousGoodsSaga';
import unitServiceSaga from '../sagas/unitServiceSaga';
import orderSaga from '../sagas/orderSaga';
import invoiceTypeSaga from '../sagas/invoiceTypeSaga';
import importExportCodeSaga from '../sagas/importExportCodeSaga';
import cancellationTypeSaga from '../sagas/cancellationTypeSaga';
import serviceSaga from '../sagas/serviceSaga';
import serviceTypeSaga from '../sagas/serviceTypeSaga';
import serviceStatusSaga from '../sagas/serviceStatusSaga';
import orderUnitSaga from '../sagas/orderUnitSaga';
import serviceProductSaga from '../sagas/serviceProductSaga';
import dangerousGoodsGroupSaga from '../sagas/dangerousGoodsGroupSaga';
import orderUnitServiceSaga from '../sagas/orderUnitServiceSaga';
import orderUnitServiceProductSaga from '../sagas/orderUnitServiceProductSaga';
import dashboardMessageTypeSaga from '../sagas/dashboardMessageTypeSaga';
import dashboardMessageSaga from '../sagas/dashboardMessageSaga';
import ousStatusSaga from '../sagas/ousStatusSaga';
import errorTypeSaga from '../sagas/errorTypeSaga';
import orderStatusSaga from '../sagas/orderStatusSaga';
import ouspStatusSaga from '../sagas/ouspStatusSaga';
import factorSetHoursSaga from '../sagas/factorSetHoursSaga';
import factorSetCancellationSaga from '../sagas/factorSetCancellationSaga';
import agreementVersionSpecialPriceSaga from '../sagas/agreementVersionSpecialPriceSaga';
import invoiceSaga from '../sagas/invoiceSaga';
import invoiceLineSaga from '../sagas/invoiceLineSaga';
import invoiceFormatSaga from '../sagas/invoiceFormatSaga';
import invoiceStatusSaga from '../sagas/invoiceStatusSaga';
import simulationOrderSaga from '../sagas/simulationOrderSaga';
import simulationProductSaga from '../sagas/simulationProductSaga';
import simulationStatusSaga from '../sagas/simulationStatusSaga';
import simulationSaga from '../sagas/simulationSaga';
import factorSetDepartureVersionSaga from '../sagas/factorSetDepartureVersionSaga';
import hoursLineSaga from '../sagas/hoursLineSaga';
import priorityLineSaga from '../sagas/priorityLineSaga';
import departureLineSaga from '../sagas/departureLineSaga';
import weightLineSaga from '../sagas/weightLineSaga';
import cancellationLineSaga from '../sagas/cancellationLineSaga';
import departureVersionStatusSaga from '../sagas/departureVersionStatusSaga';
import hoursVersionStatusSaga from '../sagas/hoursVersionStatusSaga';
import priorityVersionStatusSaga from '../sagas/priorityVersionStatusSaga';
import cancellationVersionStatusSaga from '../sagas/cancellationVersionStatusSaga';
import factorSetCancellationVersionSaga from '../sagas/factorSetCancellationVersionSaga';
import factorSetPriorityVersionSaga from '../sagas/factorSetPriorityVersionSaga';
import factorSetHoursVersionSaga from '../sagas/factorSetHoursVersionSaga';
import factorSetWeightVersionSaga from '../sagas/factorSetWeightVersionSaga';
import priorityTypeSaga from '../sagas/priorityTypeSaga';
import weightVersionStatusSaga from '../sagas/weightVersionStatusSaga';
import helptextSaga from '../sagas/helptextSaga';
import factorSetDangerousGoodsSaga from '../sagas/factorSetDangerousGoodsSaga';
import factorSetCalendarSaga from '../sagas/factorSetCalendarSaga';
import surchargeSaga from '../sagas/surchargeSaga';
import dangerousGoodsLineSaga from '../sagas/dangerousGoodsLineSaga';
import calendarLineSaga from '../sagas/calendarLineSaga';
import calendarVersionStatusSaga from '../sagas/calendarVersionStatusSaga';
import factorSetCalendarVersionSaga from '../sagas/factorSetCalendarVersionSaga';
import dangerousGoodsVersionStatusSaga from '../sagas/dangerousGoodsVersionStatusSaga';
import factorSetDangerousGoodsVersionSaga from '../sagas/factorSetDangerousGoodsVersionSaga';
import departureTypeSaga from '../sagas/departureTypeSaga';
import d365InvoiceSaga from '../sagas/d365InvoiceSaga';
import userSaga from '../sagas/userSaga';
import pricingComponentTypeSaga from '../sagas/pricingComponentTypeSaga';
import priceListUnitTypeSaga from '../sagas/priceListUnitTypeSaga';
import unitStatusSaga from '../sagas/unitStatusSaga';
import agreementVersionTransitDiscountSaga from '../sagas/agreementVersionTransitDiscountSaga';
import loadedFlagSaga from '../sagas/loadedFlagSaga';
import languageSaga from '../sagas/languageSaga';
import surchargeRateSaga from '../sagas/surchargeRateSaga';
import surchargeTypeSaga from '../sagas/surchargeTypeSaga';
import surchargeAreaSaga from '../sagas/surchargeAreaSaga';
import surchargeRateUnitSaga from '../sagas/surchargeRateUnitSaga';
import userStatusSaga from '../sagas/userStatusSaga';
import userRoleSaga from '../sagas/userRoleSaga';
import serviceArenaTypeSaga from '../sagas/serviceArenaTypeSaga';
import serviceArenaSaga from '../sagas/serviceArenaSaga';
import terminalOperatorSaga from '../sagas/terminalOperatorSaga';
import responsibilityCenterSaga from '../sagas/responsibilityCenterSaga';
import productArenaTypeSaga from '../sagas/productArenaTypeSaga';
import priceListTerminalSaga from '../sagas/priceListTerminalSaga';
import reportsSaga from '../sagas/reportsSaga';
import printAgreementVersionSaga from '../sagas/printAgreementVersionSaga';
import scheduledJobSaga from '../sagas/scheduledJobSaga';
import cancellationRuleSetSaga from '../sagas/cancellationRuleSetSaga';

export function* rootSaga() {
    yield all([
        ...customerSaga,
        ...countrySaga,
        ...sellerSaga,
        ...statusSaga,
        ...segmentSaga,
        ...customerCorporateSaga,
        ...paymentDeadlineSaga,
        ...invoiceFrequencySaga,
        ...invoiceSeparationSaga,
        ...agreementSaga,
        ...unitGroupSaga,
        ...customerGroupSaga,
        ...customerAgreementSaga,
        ...productArenaSaga,
        ...agreementVersionSaga,
        ...agreementVersionStatusSaga,
        ...productSaga,
        ...productTypesSaga,
        ...terminalSaga,
        ...currencySaga,
        ...pricelistTypeSaga,
        ...unitSubgroupsSaga,
        ...pricelistVersionSaga,
        ...pricelistSaga,
        ...pricelistVersionStatusSaga,
        ...vatSaga,
        ...dynamicsSaga,
        ...agreementVersionPriceListSaga,
        ...factorSetWeightSaga,
        ...factorSetDepartureSaga,
        ...factorSetPrioritySaga,
        ...gtsSaga,
        ...pricelistVersionProductSaga,
        ...transitDiscountSaga,
        ...gtsOrderSaga,
        ...unitTypeSaga,
        ...gtsOrderUnitSaga,
        ...gtsOrderUnitDepotLinesSaga,
        ...dangerousGoodsSaga,
        ...unitServiceSaga,
        ...orderSaga,
        ...invoiceTypeSaga,
        ...importExportCodeSaga,
        ...cancellationTypeSaga,
        ...serviceSaga,
        ...serviceTypeSaga,
        ...serviceStatusSaga,
        ...orderUnitSaga,
        ...serviceProductSaga,
        ...dangerousGoodsGroupSaga,
        ...orderUnitServiceSaga,
        ...orderUnitServiceProductSaga,
        ...dashboardMessageTypeSaga,
        ...dashboardMessageSaga,
        ...ousStatusSaga,
        ...errorTypeSaga,
        ...orderStatusSaga,
        ...ouspStatusSaga,
        ...factorSetHoursSaga,
        ...factorSetCancellationSaga,
        ...agreementVersionSpecialPriceSaga,
        ...invoiceSaga,
        ...invoiceLineSaga,
        ...invoiceFormatSaga,
        ...invoiceStatusSaga,
        ...simulationSaga,
        ...simulationOrderSaga,
        ...simulationProductSaga,
        ...simulationStatusSaga,
        ...factorSetDepartureVersionSaga,
        ...factorSetCancellationVersionSaga,
        ...factorSetPriorityVersionSaga,
        ...factorSetHoursVersionSaga,
        ...factorSetWeightVersionSaga,
        ...hoursLineSaga,
        ...priorityLineSaga,
        ...departureLineSaga,
        ...weightLineSaga,
        ...weightVersionStatusSaga,
        ...cancellationLineSaga,
        ...departureVersionStatusSaga,
        ...hoursVersionStatusSaga,
        ...priorityVersionStatusSaga,
        ...cancellationVersionStatusSaga,
        ...priorityTypeSaga,
        ...helptextSaga,
        ...factorSetDangerousGoodsSaga,
        ...factorSetCalendarSaga,
        ...surchargeSaga,
        ...dangerousGoodsLineSaga,
        ...calendarLineSaga,
        ...calendarVersionStatusSaga,
        ...factorSetCalendarVersionSaga,
        ...dangerousGoodsVersionStatusSaga,
        ...factorSetDangerousGoodsVersionSaga,
        ...departureTypeSaga,
        ...d365InvoiceSaga,
        ...userSaga,
        ...pricingComponentTypeSaga,
        ...priceListUnitTypeSaga,
        ...unitStatusSaga,
        ...agreementVersionTransitDiscountSaga,
        ...loadedFlagSaga,
        ...languageSaga,
        ...surchargeAreaSaga,
        ...surchargeRateSaga,
        ...surchargeRateUnitSaga,
        ...surchargeTypeSaga,
        ...userStatusSaga,
        ...userRoleSaga,
        ...serviceArenaTypeSaga,
        ...serviceArenaSaga,
        ...terminalOperatorSaga,
        ...responsibilityCenterSaga,
        ...productArenaTypeSaga,
        ...priceListTerminalSaga,
        ...reportsSaga,
        ...printAgreementVersionSaga,
        ...scheduledJobSaga,
        ...cancellationRuleSetSaga
    ]);
}