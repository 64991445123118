/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Container, Grid } from '@mui/material';
import React, { Component, useRef } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import Agreement from '../../interfaces/output/agreement';
import {
    deleteAgreement, fetchAgreementById, fetchAgreementVersions, getAgreement, getAgreementVersions, isLoadingAgreementVersions,
    isLoadingAgreement, resetAgreement, updateAgreement, isUpdatingAgreement, isDeletingAgreement
} from '../../reducers/agreementsReducer';
import ViewAgreement from './viewAgreement';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import CustomerAgreement from '../../interfaces/output/customerAgreement';
import {
    demoteCustomerAgreement, fetchCustomerAgreements, getCustomerAgreements, isDeletingCustomerAgreement, isUpdatingCustomerAgreement, isCreatingCustomerAgreement,
    promoteCustomerAgreement, isLoadingCustomerAgreement, resetCustomerAgreements, deactivateCustomerAgreement
} from '../../reducers/customerAgreementReducer';
import CustomerAgreementFilters from '../../interfaces/filters/customerAgreementFilters';
import AgreementVersion from '../../interfaces/output/agreementVersion';
import MainLayout from '../common/widgets/mainLayout';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import PageUtils from '../../utils/pageUtils';
import { fetchAgreementVersionTransitDiscounts, getAgreementVersionDiscount, getAgreementVersionDiscounts, isLoadingDiscounts, resetAgreementVersionTransitDiscounts }
    from '../../reducers/agreementVersionReducer';
import TransitDiscount from '../../interfaces/output/transitDiscount';
import ViewAgreementVersionDiscounts from './agreementVersion/viewAgreementVersionDiscounts';
import {
    fetchAgreementVersionPricelists, getAgreementVersionPriceLists, isCreatingAgreementPricelist, isDeletingAgreementPricelist, isLoadingAgreementVersionPriceList, isUpdatingAgreementPricelist,
    resetAgreementVersionPricelists, promoteAgreementVersionPricelists, demoteAgreementVersionPricelists, deactivateAgreementVersionPricelists, isLoadingAgreementVersionPriceLists
}
    from '../../reducers/agreementVersionPriceListReducer';
import AgreementVersionPriceListsFilters from '../../interfaces/filters/agreementVersionPriceListsFilters';
import AgreementVersionPricelist from '../../interfaces/output/agreementVersionPricelist';
import { deleteTransitDiscount, fetchTransitDiscountById, isDeletingAgreementVersionTransitDiscount } from '../../reducers/transitDiscountReducer';
import { ObjectType } from '../../constants/constants';
import PricelistList from '../pricelists/pricelistList';
import CustomerList from '../customers/customerList';
import AgreementVersionsList from './agreementVersion/agreementVersionsList';
import LanguageUtils from '../../utils/LanguageUtils';
import CustomerAgreementFiltersComponent from '../common/customerAgreementsFilter';
import {
    deactivateAgreementVersionSpecialPriceById, fetchAgreementVersionSpecialPrices, getAgreementVersionSpecialPrices, isDeletingAgreementSpecialPrice, isLoadingAgreementVersionSpecialPrices,
    resetAgreementVersionSpecialPrice
}
    from '../../reducers/agreementVersionSpecialPriceReducer';
import AgreementVersionSpecialPriceFilters from '../../interfaces/filters/agreementVersionSpecialPriceFilters';
import AgreementVersionSpecialPriceList from '../agreementVersionSpecialPrice/agreementVersionSpecialPriceList';
import AgreementVersionSpecialPriceOut from '../../interfaces/output/agreementVersionSpecialPriceOut';
import { ensure } from '../../utils/arrayUtils';
import Loader from '../common/widgets/loader';
import ExportAgreement from '../exports/agreementExport';
import PriceListFilters from '../common/priceListFilters';
import PriceListsFilters from '../../interfaces/output/priceListFilters';
import AgreementVersionSpecialPriceFilterComponent from '../agreementVersionSpecialPrice/agreementVersionSpecialPriceFiltersComponent';
import NullableFormattedDate from '../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import localStorageUtils from '../../utils/localStorageUtils';
import PrintAgreementVersion from '../../interfaces/output/printAgreemenVersion';
import {
    fetchPrintAgreementVersion, fetchPrintAgreementVersionPeriod, getPrintAgreementVersion, getPrintAgreementVersionPeriod,
    isLoadingPrintAgreementVersion, isLoadingPrintAgreementVersionPeriod,
    isRuningPrintAgreementVersion,
    resetPrintAgreementVersion,
    resetPrintAgreementVersionPeriod,
    runProcedurePrintAgreementVersion
} from '../../reducers/printAgreementVersionReducer';
import PrintAgreementVersionPeriod from '../../interfaces/output/printAgreemenVersionPeriod';
import If from '../common/widgets/if';
import { FormattedMessage } from 'react-intl';
import PrintAgreementVersionPeriodList from './printAgreementVersion/printAgreementVersionPeriodList';
import PrintAgreementVersionPeriodFilters from '../../interfaces/filters/printAgreementVersionPeriodFilters';

interface IViewAgreementDetailsProps {
    classes: any;
    theme: Theme;
    fetchAgreementById: any;
    agreement: Agreement;
    customerAgreements: Pagination<CustomerAgreement>;
    agreementVersions: Pagination<AgreementVersion>;
    pricelistAgreementVersions: Pagination<AgreementVersionPricelist>;
    agreementVersionSpecialPrices: Pagination<AgreementVersionSpecialPriceOut>;
    agreementVersionDiscounts: Pagination<TransitDiscount>;
    transitDiscount: TransitDiscount;
    fetchCustomerAgreements: any;
    promoteCustomerAgreement: any;
    demoteCustomerAgreement: any;
    fetchAgreementVersions: any;
    fetchAgreementVersionTransitDiscounts: any;
    fetchAgreementVersionById: any;
    fetchAgreementVersionPricelists: any;
    promoteAgreementVersionPricelists: any;
    demoteAgreementVersionPricelists: any;
    deactivateAgreementVersionPricelists: any;
    deactivateCustomerAgreement: any;
    fetchTransitDiscountById: any;
    deleteAgreement: any;
    deactivateAgreementVersionSpecialPriceById: any;
    fetchAgreementVersionSpecialPrices: any;
    fetchPrintAgreementVersion: any;
    match: any;
    history?: any;
    location?: any;
    isUpdating: boolean;
    isDeletingCustomerAgreement: boolean;
    isCreatingCustomerAgreement: boolean;
    isLoading: boolean;
    isLoadingAgreement: boolean;
    isDeletingAgreementSpecialPrice: boolean;
    isDeletingAgreementVersionTransitDiscount: boolean;
    isLoadingCustomerAgreement: boolean;
    isUpdatingCustomerAgreement: boolean;
    isUpdatingAgreementPricelist: boolean;
    isUpdatingAgreement: boolean;
    isCreatingAgreementPricelist: boolean;
    isDeletingAgreementPricelist: boolean;
    isLoadingAgreementVersionPriceList: boolean;
    isLoadingDiscounts: boolean;
    isLoadingAgreementVersionSpecialPrices: boolean;
    isLoadingAgreementVersions: boolean;
    isLoadingAgreementVersionPriceLists: boolean;
    isDeletingAgreement: boolean;
    isLoadingPrintAgreementVersion: boolean;
    resetCustomerAgreements: () => void;
    deleteTransitDiscount: (x: number) => void;
    resetAgreement: () => void;
    resetAgreementVersionPricelists: () => void;
    resetAgreementVersionTransitDiscounts: () => void;
    resetAgreementVersionSpecialPrice: () => void;
    updateAgreement: (x: Agreement) => void;
    printAgreementVersion: PrintAgreementVersion;
    printAgreementVersionPeriods: Pagination<PrintAgreementVersionPeriod>;
    fetchPrintAgreementVersionPeriod: any;
    runProcedurePrintAgreementVersion: any;
    isLoadingPrintAgreementVersionPeriod: boolean;
    isRuningPrintAgreementVersion: boolean;
    resetPrintAgreementVersion: () => void;
    resetPrintAgreementVersionPeriod: () => void;
}

interface IViewAgreementDetailsState {
    agreement: Agreement;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    customerAgreementFilters: CustomerAgreementFilters;
    pricelistFilters: PriceListsFilters;
    agreementVersionPriceListFilter: AgreementVersionPriceListsFilters;
    agreementVersionSpecialPriceFilters: AgreementVersionSpecialPriceFilters;
    serverPaginationPriceList: Paging;
    serverPaginationSpecialPrice: Paging;
    serverPaginationAgreementVTransitDiscount: Paging;
    serverPaginationCustomerAgreement: Paging;
    serverPaginationAgreementVersion: Paging;
    sortModelPriceList: GridSortModel;
    sortModelSpecialPrice: GridSortModel;
    sortModelAgreementVTransitDiscount: GridSortModel;
    sortModelCustomerAgreement: GridSortModel;
    sortModelAgreementVersion: GridSortModel;
    openModal: boolean;
    columnVisibilityModelAgreementVersion: GridColumnVisibilityModel;
    columnVisibilityModelCustomer: GridColumnVisibilityModel;
    columnVisibilityModelAgreementVersionDiscount: GridColumnVisibilityModel;
    columnVisibilityModelAgreementVersionSpecialPL: GridColumnVisibilityModel;
    columnVisibilityModelPriceList: GridColumnVisibilityModel;
    componentId?: string;
    filtersPrintAgreementVersionPeriod: PrintAgreementVersionPeriodFilters;
    pagingPrintAgreementVersionPeriod: Paging;
    columnVisibilityModelPrintAgreementVersionPeriod: GridColumnVisibilityModel;
    sortModelPrintAgreementVersionPeriod: GridSortModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAgreementById: (id: number, agreementVersionId?: number) => dispatch(fetchAgreementById({
        id,
        agreementVersionId
    })),
    fetchCustomerAgreements: (paging: Paging, filters: CustomerAgreementFilters) => dispatch(fetchCustomerAgreements({
        paging,
        filters
    })),
    fetchAgreementVersionSpecialPrices: (paging: Paging, filters: AgreementVersionSpecialPriceFilters) => dispatch(fetchAgreementVersionSpecialPrices({
        paging,
        filters
    })),
    promoteCustomerAgreement: (id: number) => dispatch(promoteCustomerAgreement(id)),
    demoteCustomerAgreement: (id: number) => dispatch(demoteCustomerAgreement(id)),
    promoteAgreementVersionPricelists: (id: number) => dispatch(promoteAgreementVersionPricelists(id)),
    demoteAgreementVersionPricelists: (id: number) => dispatch(demoteAgreementVersionPricelists(id)),
    deactivateAgreementVersionPricelists: (id: number) => dispatch(deactivateAgreementVersionPricelists(id)),
    deactivateAgreementVersionSpecialPriceById: (id: number) => dispatch(deactivateAgreementVersionSpecialPriceById(id)),
    deactivateCustomerAgreement: (id: number) => dispatch(deactivateCustomerAgreement(id)),
    fetchTransitDiscountById: (id: number) => dispatch(fetchTransitDiscountById(id)),
    fetchAgreementVersions: (id: number, paging: Paging) => dispatch(fetchAgreementVersions({
        id,
        paging
    })),
    fetchAgreementVersionTransitDiscounts: (agreementVersionId: string, paging: Paging) => dispatch(fetchAgreementVersionTransitDiscounts({
        agreementVersionId,
        paging
    })),
    fetchAgreementVersionPricelists: (paging: Paging, filters: AgreementVersionPriceListsFilters) => dispatch(fetchAgreementVersionPricelists({
        paging,
        filters
    })),
    deleteAgreement: (agreement: Agreement) => dispatch(deleteAgreement(agreement)),
    updateAgreement: (agreement: Agreement) => dispatch(updateAgreement(agreement)),
    resetCustomerAgreements: () => dispatch(resetCustomerAgreements()),
    resetAgreement: () => dispatch(resetAgreement()),
    resetAgreementVersionPricelists: () => dispatch(resetAgreementVersionPricelists()),
    resetAgreementVersionTransitDiscounts: () => dispatch(resetAgreementVersionTransitDiscounts()),
    resetAgreementVersionSpecialPrice: () => dispatch(resetAgreementVersionSpecialPrice()),
    deleteTransitDiscount: (discountId: number) => dispatch(deleteTransitDiscount(discountId)),
    fetchPrintAgreementVersion: (agreementVersionId: number) => dispatch(fetchPrintAgreementVersion(agreementVersionId)),
    runProcedurePrintAgreementVersion: (agreementVersionId: number) => dispatch(runProcedurePrintAgreementVersion(agreementVersionId)),
    fetchPrintAgreementVersionPeriod: (paging: Paging, filters: PrintAgreementVersionPeriodFilters) => dispatch(fetchPrintAgreementVersionPeriod({
        paging,
        filters
    })),
    resetPrintAgreementVersion: () => dispatch(resetPrintAgreementVersion()),
    resetPrintAgreementVersionPeriod: () => dispatch(resetPrintAgreementVersionPeriod())
});

const mapStoreToProps = (store: RootState) => {
    return {
        agreement: getAgreement(store),
        customerAgreements: getCustomerAgreements(store),
        agreementVersions: getAgreementVersions(store),
        agreementVersionDiscounts: getAgreementVersionDiscounts(store),
        isUpdatingCustomerAgreement: isUpdatingCustomerAgreement(store),
        isDeletingCustomerAgreement: isDeletingCustomerAgreement(store),
        isCreatingCustomerAgreement: isCreatingCustomerAgreement(store),
        isUpdatingAgreementPricelist: isUpdatingAgreementPricelist(store),
        isDeletingAgreementPricelist: isDeletingAgreementPricelist(store),
        isCreatingAgreementPricelist: isCreatingAgreementPricelist(store),
        pricelistAgreementVersions: getAgreementVersionPriceLists(store),
        agreementVersionSpecialPrices: getAgreementVersionSpecialPrices(store),
        transitDiscount: getAgreementVersionDiscount(store),
        isDeletingAgreementSpecialPrice: isDeletingAgreementSpecialPrice(store),
        isLoadingCustomerAgreement: isLoadingCustomerAgreement(store),
        isLoadingAgreement: isLoadingAgreement(store),
        isUpdatingAgreement: isUpdatingAgreement(store),
        isLoadingAgreementVersionPriceList: isLoadingAgreementVersionPriceList(store),
        isLoadingAgreementVersionPriceLists: isLoadingAgreementVersionPriceLists(store),
        isLoadingAgreementVersionSpecialPrices: isLoadingAgreementVersionSpecialPrices(store),
        isLoadingDiscounts: isLoadingDiscounts(store),
        isLoadingAgreementVersions: isLoadingAgreementVersions(store),
        isDeletingAgreement: isDeletingAgreement(store),
        isDeletingAgreementVersionTransitDiscount: isDeletingAgreementVersionTransitDiscount(store),
        isLoadingPrintAgreementVersion: isLoadingPrintAgreementVersion(store),
        isLoadingPrintAgreementVersionPeriod: isLoadingPrintAgreementVersionPeriod(store),
        isLoading: isLoadingCustomerAgreement(store) || isUpdatingCustomerAgreement(store) || isDeletingCustomerAgreement(store) || isCreatingCustomerAgreement(store) ||
            isLoadingAgreementVersionPriceList(store) || isDeletingAgreementPricelist(store) || isUpdatingAgreementPricelist(store) || isCreatingAgreementPricelist(store)
            || isLoadingPrintAgreementVersion(store) || isLoadingPrintAgreementVersionPeriod(store) || isRuningPrintAgreementVersion(store),
        printAgreementVersion: getPrintAgreementVersion(store),
        printAgreementVersionPeriods: getPrintAgreementVersionPeriod(store),
        isRuningPrintAgreementVersion: isRuningPrintAgreementVersion(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    activate: LanguageUtils.createMessage('Activate'),
    agreement: LanguageUtils.createMessage('Agreement'),
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    promote: LanguageUtils.createMessage('Promote'),
    demote: LanguageUtils.createMessage('Demote'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    properties: LanguageUtils.createMessage('Properties'),
    pricelists: LanguageUtils.createMessage('Price lists'),
    specialPrices: LanguageUtils.createMessage('Special price'),
    customers: LanguageUtils.createMessage('Customers'),
    transitDiscounts: LanguageUtils.createMessage('Transit discounts'),
    versions: LanguageUtils.createMessage('Versions'),
    filter: LanguageUtils.createMessage('Filter'),
    export: LanguageUtils.createMessage('Export'),
    printOut: LanguageUtils.createMessage('Print out'),
    generatePrintAgreementPeriods: LanguageUtils.createMessage('Generate report')
};

class AgreementDetails extends Component<IViewAgreementDetailsProps, IViewAgreementDetailsState> {

    agreementVersionPriceListRef: any;
    customerListRef: any;
    priceListRef: any;
    agreementVersionListRef: any;
    printAgreementVersionPeriodListRef: any;
    constructor(props: IViewAgreementDetailsProps) {
        super(props);
        const { agreementId, agreementVersionId } = props.match.params;
        this.agreementVersionPriceListRef = React.createRef();
        this.customerListRef = React.createRef();
        this.priceListRef = React.createRef();
        this.agreementVersionListRef = React.createRef();
        this.printAgreementVersionPeriodListRef = React.createRef();
        this.state = {
            agreement: {} as Agreement,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPaginationPriceList: PageUtils.getDefaultPaging(),
            serverPaginationSpecialPrice: PageUtils.getDefaultPaging(),
            serverPaginationAgreementVTransitDiscount: PageUtils.getDefaultPaging(),
            serverPaginationCustomerAgreement: PageUtils.getDefaultPaging(),
            serverPaginationAgreementVersion: PageUtils.getDefaultPaging(),
            customerAgreementFilters: {
                customerId: null,
                agreementId: agreementId
            },
            pricelistFilters: {} as PriceListsFilters,
            agreementVersionPriceListFilter: {
                pricelistId: null,
                agreementVersionId: agreementVersionId,
                pricelistFilters: {} as PriceListsFilters
            } as AgreementVersionPriceListsFilters,
            agreementVersionSpecialPriceFilters: {
                agreementId
            } as AgreementVersionSpecialPriceFilters,
            openModal: false,
            sortModelPriceList: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelSpecialPrice: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelAgreementVTransitDiscount: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelCustomerAgreement: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelAgreementVersion: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModelAgreementVersion: {},
            columnVisibilityModelCustomer: {
                customer_corporate_name: false,
                customer_status_name: false,
                customer_revenueThisYear: false,
                customer_revenueLastYear: false
            },
            columnVisibilityModelAgreementVersionDiscount: {},
            columnVisibilityModelAgreementVersionSpecialPL: {},
            columnVisibilityModelPriceList: {
                pricelist_pricingComponentType_name: false,
                pricelist_unitSubGroup_name: false
            },
            componentId: undefined,
            filtersPrintAgreementVersionPeriod: {} as PrintAgreementVersionPeriodFilters,
            pagingPrintAgreementVersionPeriod: PageUtils.getDefaultPaging(),
            columnVisibilityModelPrintAgreementVersionPeriod: {},
            sortModelPrintAgreementVersionPeriod: [{
                field: 'validFromDate',
                sort: 'desc' as GridSortDirection
            } as GridSortItem]

        };
    }

    componentDidMount() {
        const { fetchAgreementById } = this.props;
        const { agreementId, agreementVersionId } = this.props.match.params;

        const versionId = agreementVersionId === 'latest' ? undefined : agreementVersionId;

        fetchAgreementById(agreementId, versionId);
    }

    componentDidUpdate(prevProps: IViewAgreementDetailsProps) {
        const { agreement, fetchAgreementById, isLoadingAgreement, isDeletingAgreementSpecialPrice, isUpdatingCustomerAgreement, isDeletingAgreementVersionTransitDiscount,
            isDeletingAgreementPricelist, isDeletingCustomerAgreement, isLoadingAgreementVersions, printAgreementVersion, fetchPrintAgreementVersionPeriod,
            isLoadingPrintAgreementVersion, isRuningPrintAgreementVersion, fetchPrintAgreementVersion } = this.props;
        const { agreementId, agreementVersionId } = this.props.match.params;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { serverPaginationAgreementVersion, filtersPrintAgreementVersionPeriod, pagingPrintAgreementVersionPeriod,sortModelPrintAgreementVersionPeriod } = this.state;

        if(agreement !== prevProps.agreement) {
            this.setState({ agreement });

            const agreementIdFromStorage = localStorageUtils.getItem(localStorageUtils.LOCAL_STORAGE_OBJECT_IDS.AGREEMENT_ID) !== null ?
                localStorageUtils.getItem(localStorageUtils.LOCAL_STORAGE_OBJECT_IDS.AGREEMENT_ID) : null;

            if(agreementIdFromStorage !== null && agreementIdFromStorage !== agreement?.id) {
                localStorageUtils.clearItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION);
            }
            if(agreement?.id !== null && agreement?.id !== undefined && agreementIdFromStorage !== agreement?.id) {
                localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_OBJECT_IDS.AGREEMENT_ID, agreement.id);
            }

        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.agreementVersionId !== agreementVersionId) {
                const versionId = agreementVersionId === 'latest' ? undefined : agreementVersionId;
                fetchAgreementById(agreementId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingAgreement && prevProps.isLoadingAgreement || !isDeletingAgreementSpecialPrice && prevProps.isDeletingAgreementSpecialPrice
            || !isUpdatingCustomerAgreement && prevProps.isUpdatingCustomerAgreement || !isDeletingAgreementVersionTransitDiscount && prevProps.isDeletingAgreementVersionTransitDiscount
            || !isDeletingAgreementPricelist && prevProps.isDeletingAgreementPricelist || !isDeletingCustomerAgreement && prevProps.isDeletingCustomerAgreement)) {
            this.loadData(selectedMenuItem);
        }

        if(!isRuningPrintAgreementVersion && prevProps.isRuningPrintAgreementVersion) {
            const { agreement } = this.props;

            if(agreement.version?.id) {
                fetchPrintAgreementVersion(agreement.version?.id);
            }
        }

        if(!isLoadingAgreementVersions && prevProps.isLoadingAgreementVersions) {
            if(this.agreementVersionListRef !== null && this.agreementVersionListRef !== undefined &&
                this.agreementVersionListRef.current !== null && this.agreementVersionListRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.agreementVersionListRef.current.setDataGridPage(serverPaginationAgreementVersion?.page);
            }
        }

        if(!isLoadingPrintAgreementVersion && prevProps.isLoadingPrintAgreementVersion) {
            if(printAgreementVersion?.ready) {
                const filters = {
                    ...filtersPrintAgreementVersionPeriod,
                    printAgreementVersionId: printAgreementVersion?.id
                } as PrintAgreementVersionPeriodFilters;

                const newPagination = {
                    ...pagingPrintAgreementVersionPeriod,
                    sort: sortModelPrintAgreementVersionPeriod
                };

                fetchPrintAgreementVersionPeriod(newPagination, filters);
            }
        }

        if(!isLoadingPrintAgreementVersionPeriod && prevProps.isLoadingPrintAgreementVersionPeriod) {
            if(this.printAgreementVersionPeriodListRef !== null && this.printAgreementVersionPeriodListRef !== undefined &&
                this.printAgreementVersionPeriodListRef.current !== null && this.printAgreementVersionPeriodListRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.printAgreementVersionPeriodListRef?.current?.setDataGridPage(pagingPrintAgreementVersionPeriod?.page);
            }
        }
    }

    componentWillUnmount() {
        const { resetCustomerAgreements, resetAgreement, resetAgreementVersionPricelists,
            resetAgreementVersionTransitDiscounts, resetPrintAgreementVersion, resetPrintAgreementVersionPeriod } = this.props;
        resetCustomerAgreements();
        resetAgreement();
        resetAgreementVersionPricelists();
        resetAgreementVersionTransitDiscounts();
        resetPrintAgreementVersion();
        resetPrintAgreementVersionPeriod();
    }

    isHiddenCategory = (): boolean => {
        const { agreement } = this.state;

        return agreement.version?.id ? false : true;
    }

    configureDrawer = (props: IViewAgreementDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.AGREEMENTS}/${props.match.params.agreementId}/${props.match.params.agreementVersionId}`;

        return ImmutableList<IMenuItem>([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            url: `${baseUrl}/properties`,
            additionalMargin: true,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.customers,
            icon: <FontAwesomeIcon icon="user-friends" size="1x" transform="grow-4" />,
            url: `${baseUrl}/customers`,
            additionalMargin: true,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/customers`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.versions,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 4,
            name: messages.transitDiscounts,
            icon: <FontAwesomeIcon icon="cut" size="1x" transform="grow-4" />,
            url: `${baseUrl}/discounts`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/discounts`, props.location.pathname)
        },
        {
            id: 5,
            name: messages.pricelists,
            icon: <FontAwesomeIcon icon="tags" size="1x" transform="grow-4" />,
            url: `${baseUrl}/pricelist`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/pricelist`, props.location.pathname)
        },
        {
            id: 6,
            name: messages.specialPrices,
            icon: <FontAwesomeIcon icon="hand-holding-usd" size="1x" transform="grow-4" />,
            url: `${baseUrl}/specialprice`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/specialprice`, props.location.pathname)
        },
        {
            id: 7,
            name: messages.printOut,
            icon: <FontAwesomeIcon icon="print" size="1x" transform="grow-4" />,
            url: `${baseUrl}/printOut`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/printOut`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }
        ]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { agreementId } = this.props.match.params;
        const { fetchCustomerAgreements, fetchAgreementVersions, fetchAgreementVersionTransitDiscounts, agreement, fetchAgreementVersionPricelists,
            fetchAgreementVersionSpecialPrices, fetchPrintAgreementVersion } = this.props;
        const { customerAgreementFilters } = this.state;
        const { agreementVersionSpecialPriceFilters } = this.state;

        const pricelistAgreementFilters = {
            pricelistId: null,
            agreementVersionId: agreement.version?.id
        } as AgreementVersionPriceListsFilters;

        const specialPriceFilters = {
            ...agreementVersionSpecialPriceFilters,
            agreementId: agreement.id,
            agreementVersionId: agreement.version?.id
        } as AgreementVersionSpecialPriceFilters;

        const paging = PageUtils.getDefaultPaging();
        const currentVersionPagination = localStorageUtils.getItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION) !== null ?
            localStorageUtils.getItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION) : paging;
        this.setState({ serverPaginationPriceList: paging });
        this.setState({ serverPaginationSpecialPrice: paging });
        this.setState({ serverPaginationAgreementVTransitDiscount: paging });
        this.setState({ serverPaginationCustomerAgreement: paging });
        this.setState({ serverPaginationAgreementVersion: currentVersionPagination as Paging });

        if(selectedMenuItem?.id === 2) {
            fetchCustomerAgreements(paging, customerAgreementFilters);
        }

        if(selectedMenuItem?.id === 3) {
            fetchAgreementVersions(agreementId, currentVersionPagination);
        }

        if(selectedMenuItem?.id === 4 && agreement.version?.id) {
            fetchAgreementVersionTransitDiscounts(agreement.version?.id, paging);
        }

        if(selectedMenuItem?.id === 5 && agreement.version?.id) {
            fetchAgreementVersionPricelists(paging, pricelistAgreementFilters);
        }

        if(selectedMenuItem?.id === 6 && agreement.version?.id) {
            fetchAgreementVersionSpecialPrices(paging, specialPriceFilters);
        }
        if(selectedMenuItem?.id === 7 && agreement.version?.id) {
            fetchPrintAgreementVersion(agreement.version?.id);
        }
    }
    getSelectedMenuItem = (state: IViewAgreementDetailsState) => state.menuItems.find(item => item.isSelected);

    promoteCustomerAgreement = () => {
        const { promoteCustomerAgreement } = this.props;
        const { selectedRow } = this.state;

        promoteCustomerAgreement(selectedRow);
    }

    demoteCustomerAgreement = () => {
        const { selectedRow } = this.state;
        const { demoteCustomerAgreement } = this.props;

        demoteCustomerAgreement(selectedRow);
    }

    deactivateAgreement = () => {
        const { agreement } = this.state;
        const { deleteAgreement } = this.props;

        deleteAgreement(agreement);
    }

    activateAgreement = () => {
        const { agreement } = this.state;
        const { updateAgreement } = this.props;

        updateAgreement({
            ...agreement,
            active: true
        } as Agreement);
    }

    promoteAgreementVersionPricelist = () => {
        const { promoteAgreementVersionPricelists } = this.props;
        const { selectedRow } = this.state;

        promoteAgreementVersionPricelists(selectedRow);
    }

    demoteAgreementVersionPricelist = () => {
        const { selectedRow } = this.state;
        const { demoteAgreementVersionPricelists } = this.props;

        demoteAgreementVersionPricelists(selectedRow);
    }

    deactivateAgreementVersionPricelist = () => {
        const { selectedRow } = this.state;
        const { deactivateAgreementVersionPricelists } = this.props;

        deactivateAgreementVersionPricelists(selectedRow);
    }

    deleteTransitDiscount = () => {
        const { selectedRow } = this.state;
        const { deleteTransitDiscount } = this.props;

        deleteTransitDiscount(ensure(selectedRow));
    }

    deactivateAgreementVersionSpecialPrice = () => {
        const { selectedRow } = this.state;
        const { deactivateAgreementVersionSpecialPriceById } = this.props;

        deactivateAgreementVersionSpecialPriceById(selectedRow);
    }

    deactivateCustomerAgreement = () => {
        const { selectedRow } = this.state;
        const { deactivateCustomerAgreement } = this.props;

        deactivateCustomerAgreement(selectedRow);
    }

    handleMenuItems = () => {
        const { agreement, menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { agreementId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: agreement.version?.id ? `${UrlConstants.AGREEMENTS}/${agreementId}/${agreement.version?.id}/edit` : `${UrlConstants.AGREEMENTS}/${agreementId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        text: messages.export,
                        icon: 'download',
                        onClick: () => this.setState({ openModal: true })
                    },
                    {
                        icon: 'handshake-slash',
                        text: messages.delete,
                        onClick: this.deactivateAgreement,
                        isHidden: !agreement.active
                    },
                    {
                        icon: 'handshake',
                        text: messages.activate,
                        onClick: this.activateAgreement,
                        isHidden: !!agreement.active
                    }
                ];
            }

            case 3: { //agreement versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.AGREEMENTS}/${agreementId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 4: { //transit discounts
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/add/discount`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !agreement.version?.id
                        }
                    ];
                }

                if(agreement.version) {
                    return [
                        {
                            href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/discountId/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deleteTransitDiscount
                        }
                    ];
                }

                return [];
            }

            case 6: { //special price lists
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/add/agreementversionspecialprice`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !agreement.version?.id
                        }
                    ];
                }

                return [
                    {
                        href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/agreementversionspecialprice/${selectedRow}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateAgreementVersionSpecialPrice
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    onCustomerAgreementFilterChange = (attribute: string, value: any) => {
        const { customerAgreementFilters, serverPaginationCustomerAgreement } = this.state;

        const newFilter = { ...customerAgreementFilters } as CustomerAgreementFilters;
        (newFilter as any)[attribute] = value;

        const newCustomPage = PageUtils.getCustomPaging(serverPaginationCustomerAgreement.size);

        this.setState({
            customerAgreementFilters: newFilter,
            serverPaginationCustomerAgreement: newCustomPage
        });
    }

    onPricelistFilterChange = (attribute: string, value: any) => {
        const { agreementVersionPriceListFilter, serverPaginationPriceList } = this.state;

        const newPriceListFilter = agreementVersionPriceListFilter.pricelistFilters as PriceListsFilters;
        (newPriceListFilter as any)[attribute] = value;

        const newAgreementVersionPriceListFilter = {
            ...agreementVersionPriceListFilter,
            pricelistFilters: newPriceListFilter
        } as AgreementVersionPriceListsFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...serverPaginationPriceList,
            page: newPaging.page
        };

        this.setState({
            agreementVersionPriceListFilter: newAgreementVersionPriceListFilter,
            serverPaginationPriceList: newServerPagination
        });
    }

    onAgreementVersionSpecialPriceFilterChange = (attribute: string, value: any) => {
        const { agreementVersionSpecialPriceFilters, serverPaginationSpecialPrice } = this.state;

        const newFilter = { ...agreementVersionSpecialPriceFilters } as AgreementVersionSpecialPriceFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...serverPaginationSpecialPrice,
            page: newPaging.page
        };

        this.setState({
            agreementVersionSpecialPriceFilters: newFilter,
            serverPaginationSpecialPrice: newServerPagination
        });
    }
    setDefaultState = () => {
        const { fetchCustomerAgreements } = this.props;
        const { customerAgreementFilters, serverPaginationCustomerAgreement } = this.state;
        const newPaging = PageUtils.getDefaultPaging();
        const newCustomerAgreementFIlter = {
            ...customerAgreementFilters,
            validity: undefined
        } as CustomerAgreementFilters;

        const newServerPagination = {
            ...serverPaginationCustomerAgreement,
            page: newPaging.page
        };
        this.setState({
            customerAgreementFilters: newCustomerAgreementFIlter,
            serverPaginationCustomerAgreement: newServerPagination
        });
        if(this.customerListRef !== null && this.customerListRef !== undefined &&
            this.customerListRef.current !== null && this.customerListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerListRef.current.resetDataGridPage();
        }
        fetchCustomerAgreements(newServerPagination, newCustomerAgreementFIlter);
    }

    setAgreementVersionSpecialPriceDefaultState = () => {
        const { fetchAgreementVersionSpecialPrices, agreement } = this.props;
        const { serverPaginationSpecialPrice } = this.state;
        const newPaging = PageUtils.getDefaultPaging();
        const newFilters = {
            agreementId: agreement.id,
            agreementVersionId: agreement.version?.id
        } as AgreementVersionSpecialPriceFilters;

        const newServerPagination = {
            ...serverPaginationSpecialPrice,
            page: newPaging.page
        };

        this.setState({
            serverPaginationSpecialPrice: newServerPagination,
            agreementVersionSpecialPriceFilters: newFilters
        });

        fetchAgreementVersionSpecialPrices(newServerPagination, newFilters);
        if(this.agreementVersionPriceListRef !== null && this.agreementVersionPriceListRef !== undefined &&
            this.agreementVersionPriceListRef.current !== null && this.agreementVersionPriceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.agreementVersionPriceListRef.current.resetDataGridPage();
        }
    }

    setPricelistFiltersDefaultState = () => {
        const { fetchAgreementVersionPricelists, agreement } = this.props;
        const { serverPaginationPriceList } = this.state;

        const newFilters = {
            agreementVersionId: agreement.version?.id,
            pricelistFilters: {} as PriceListsFilters
        } as AgreementVersionPriceListsFilters;

        const newPaging = PageUtils.getDefaultPaging();

        const newServerPagination = {
            ...serverPaginationPriceList,
            page: newPaging.page
        };

        this.setState({
            serverPaginationPriceList: newServerPagination,
            agreementVersionPriceListFilter: newFilters
        });

        if(this.priceListRef !== null && this.priceListRef !== undefined &&
            this.priceListRef.current !== null && this.priceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.priceListRef.current.resetDataGridPage();
        }

        fetchAgreementVersionPricelists(newServerPagination, newFilters);
    }

    applyFilters = () => {
        const { fetchCustomerAgreements } = this.props;
        const { serverPaginationCustomerAgreement, customerAgreementFilters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...serverPaginationCustomerAgreement,
            page: newPaging.page
        };
        this.setState({
            serverPaginationCustomerAgreement: newServerPagination
        });
        if(this.customerListRef !== null && this.customerListRef !== undefined &&
            this.customerListRef.current !== null && this.customerListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerListRef.current.resetDataGridPage();
        }
        fetchCustomerAgreements(newServerPagination, customerAgreementFilters);
    }

    applyPricelistFilters = () => {
        const { fetchAgreementVersionPricelists } = this.props;
        const { serverPaginationPriceList, agreementVersionPriceListFilter } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...serverPaginationPriceList,
            page: newPaging.page
        };
        this.setState({
            serverPaginationPriceList: newServerPagination
        });
        if(this.priceListRef !== null && this.priceListRef !== undefined &&
            this.priceListRef.current !== null && this.priceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.priceListRef.current.resetDataGridPage();
        }

        fetchAgreementVersionPricelists(newServerPagination, agreementVersionPriceListFilter);
    }

    applyAgreementVersionSpecialPriceFilters = () => {
        const { fetchAgreementVersionSpecialPrices, agreement } = this.props;
        const { serverPaginationSpecialPrice, agreementVersionSpecialPriceFilters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...serverPaginationSpecialPrice,
            page: newPaging.page
        };
        this.setState({
            serverPaginationSpecialPrice: newServerPagination
        });

        const newFilters = {
            ...agreementVersionSpecialPriceFilters,
            agreementId: agreement.id,
            agreementVersionId: agreement.version?.id
        } as AgreementVersionSpecialPriceFilters;

        fetchAgreementVersionSpecialPrices(newServerPagination, newFilters);

        if(this.agreementVersionPriceListRef !== null && this.agreementVersionPriceListRef !== undefined &&
            this.agreementVersionPriceListRef.current !== null && this.agreementVersionPriceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.agreementVersionPriceListRef.current.resetDataGridPage();
        }

    }

    _setOpenModal = () => {
        const { openModal } = this.state;
        this.setState({ openModal: !openModal });
    }

    _onSortModelPriceListChange = (newModel: GridSortModel) => {
        const { fetchAgreementVersionPricelists } = this.props;
        const { serverPaginationPriceList, sortModelPriceList, agreementVersionPriceListFilter, agreement } = this.state;

        if(JSON.stringify(sortModelPriceList) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationPriceList,
                sort: newModel
            };
            this.setState({
                sortModelPriceList: newModel,
                serverPaginationPriceList: newPagination
            });

            fetchAgreementVersionPricelists(newPagination, {
                ...agreementVersionPriceListFilter,
                agreementVersionId: agreement.version?.id
            } as AgreementVersionPriceListsFilters);
        }
    }

    _onSortModelVersionSpecialPriceChange = (newModel: GridSortModel) => {
        const { fetchAgreementVersionSpecialPrices } = this.props;
        const { serverPaginationSpecialPrice, sortModelSpecialPrice, agreementVersionSpecialPriceFilters, agreement } = this.state;

        if(JSON.stringify(sortModelSpecialPrice) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationSpecialPrice,
                sort: newModel
            };
            this.setState({
                sortModelSpecialPrice: newModel,
                serverPaginationSpecialPrice: newPagination
            });

            fetchAgreementVersionSpecialPrices(newPagination, {
                ...agreementVersionSpecialPriceFilters,
                agreementId: agreement.id,
                agreementVersionId: agreement.version?.id
            } as AgreementVersionSpecialPriceFilters);
        }
    }

    _onSortModelTransitDiscountChange = (newModel: GridSortModel) => {
        const { fetchAgreementVersionTransitDiscounts } = this.props;
        const { sortModelAgreementVTransitDiscount, serverPaginationAgreementVTransitDiscount, agreement } = this.state;

        if(JSON.stringify(sortModelAgreementVTransitDiscount) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationAgreementVTransitDiscount,
                sort: newModel
            };
            this.setState({
                sortModelAgreementVTransitDiscount: newModel,
                serverPaginationAgreementVTransitDiscount: newPagination
            });

            fetchAgreementVersionTransitDiscounts(agreement?.version?.id, newPagination);
        }
    }
    _onSortModelCustomerAgreementChange = (newModel: GridSortModel) => {
        const { fetchCustomerAgreements } = this.props;
        const { serverPaginationCustomerAgreement, sortModelCustomerAgreement, customerAgreementFilters, agreement } = this.state;

        if(JSON.stringify(sortModelCustomerAgreement) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationCustomerAgreement,
                sort: newModel
            };
            this.setState({
                sortModelCustomerAgreement: newModel,
                serverPaginationCustomerAgreement: newPagination
            });

            fetchCustomerAgreements(newPagination, {
                ...customerAgreementFilters,
                agreementId: agreement.id
            } as CustomerAgreementFilters);
        }
    }

    _onSortModelAgreementVersionChange = (newModel: GridSortModel) => {
        const { fetchAgreementVersions } = this.props;
        const { serverPaginationAgreementVersion, sortModelAgreementVersion, agreement } = this.state;

        if(JSON.stringify(sortModelAgreementVersion) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationAgreementVersion,
                sort: newModel
            };
            this.setState({
                sortModelAgreementVersion: newModel,
                serverPaginationAgreementVersion: newPagination
            });
            fetchAgreementVersions(agreement.id, newPagination);

            localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION, newPagination);

        }
    }

    _onSortModelPrintAgreementVersionPeriodChange = (newModel: GridSortModel) => {
        const { fetchPrintAgreementVersionPeriod, printAgreementVersion } = this.props;
        const { pagingPrintAgreementVersionPeriod, sortModelPrintAgreementVersionPeriod, filtersPrintAgreementVersionPeriod } = this.state;

        if(JSON.stringify(sortModelPrintAgreementVersionPeriod) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...pagingPrintAgreementVersionPeriod,
                sort: newModel
            };
            this.setState({
                sortModelPrintAgreementVersionPeriod: newModel,
                serverPaginationAgreementVersion: newPagination
            });
            const filters = {
                ...filtersPrintAgreementVersionPeriod,
                printAgreementVersionId: printAgreementVersion?.id
            } as PrintAgreementVersionPeriodFilters;

            fetchPrintAgreementVersionPeriod(newPagination, filters);

            localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION, newPagination);

        }
    }

    setCurrentComponentId = (value: any) => {
        this.setState({
            componentId: value
        });
    };

    _runProcedurePrintAgreementVersion = () => {
        const { agreementVersionId } = this.props.match.params;
        const { runProcedurePrintAgreementVersion, agreement } = this.props;
        const versionId = agreementVersionId === 'latest' ? agreement?.version?.id : agreementVersionId;
        runProcedurePrintAgreementVersion(versionId);

    }

    render() {
        const { classes, customerAgreements, agreementVersions, isLoadingAgreement, agreementVersionDiscounts, pricelistAgreementVersions, isDeletingAgreementPricelist, isDeletingCustomerAgreement,
            isLoadingCustomerAgreement, isLoadingDiscounts, fetchCustomerAgreements, agreementVersionSpecialPrices, isLoadingAgreementVersionSpecialPrices, fetchAgreementVersionSpecialPrices,
            isDeletingAgreementSpecialPrice, isUpdatingCustomerAgreement, isLoadingAgreementVersions, isDeletingAgreementVersionTransitDiscount, isLoadingAgreementVersionPriceLists,
            fetchAgreementVersions, isUpdatingAgreement, isDeletingAgreement, history, fetchAgreementVersionPricelists, fetchAgreementVersionTransitDiscounts, printAgreementVersion,
            isLoadingPrintAgreementVersionPeriod, fetchPrintAgreementVersionPeriod, printAgreementVersionPeriods, isRuningPrintAgreementVersion, isLoadingPrintAgreementVersion
        } = this.props;
        const { agreementId } = this.props.match.params;
        const { agreement, menuItems, selectedRow, customerAgreementFilters, serverPaginationPriceList, serverPaginationSpecialPrice,
            serverPaginationAgreementVTransitDiscount, serverPaginationCustomerAgreement, serverPaginationAgreementVersion, openModal, agreementVersionPriceListFilter,
            agreementVersionSpecialPriceFilters, pricelistFilters, columnVisibilityModelPriceList, columnVisibilityModelAgreementVersionSpecialPL,
            columnVisibilityModelCustomer, columnVisibilityModelAgreementVersionDiscount, columnVisibilityModelAgreementVersion, componentId,
            pagingPrintAgreementVersionPeriod, columnVisibilityModelPrintAgreementVersionPeriod, filtersPrintAgreementVersionPeriod,sortModelPrintAgreementVersionPeriod } = this.state;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingAgreement}
                menuItem={{
                    id: 1,
                    subname: messages.agreement,
                    value: agreement.name,
                    icon: <FontAwesomeIcon icon="handshake" size="1x" />
                } as IMenuItem}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Agreement}
                componentId={componentId}
                additionalElement={
                    <strong>
                        {!!agreement?.version?.versionNumber &&
                            (<>
                                <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                                    <span className={classes.values}> {agreement?.version?.versionNumber ?? '-'} </span>
                                </span>
                                <span>
                                    <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                                    {agreement?.version?.validFromDate || agreement?.version?.validToDate ? (
                                        <span className={classes.values}>
                                            <NullableFormattedDate value={agreement?.version?.validFromDate} /> -
                                            {agreement?.version?.validToDate &&
                                                <> <NullableFormattedDate value={agreement?.version?.validToDate} /></>}
                                        </span>
                                    )
                                        : '-'} </span>
                            </>)
                        }
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/properties`}>
                            <Loader isLoading={isUpdatingAgreement || isDeletingAgreement}>
                                <ViewAgreement agreement={agreement} setCurrentComponentId={this.setCurrentComponentId} />
                            </Loader>
                            <ExportAgreement setOpenModal={this._setOpenModal} open={openModal} agreementId={agreementId} />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/pricelist`}>
                            <PriceListFilters filters={agreementVersionPriceListFilter?.pricelistFilters ?? pricelistFilters}
                                setDefaultState={this.setPricelistFiltersDefaultState} applyFilters={this.applyPricelistFilters}
                                onChange={this.onPricelistFilterChange}
                                hideInAgreementVersionPriceList={true}
                                actions={[
                                    {
                                        href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/add/agreementversionpricelist`,
                                        icon: 'plus',
                                        text: messages.add,
                                        isHidden: !!selectedRow,
                                        disabled: !agreement.version?.id
                                    },
                                    {
                                        href: `${UrlConstants.AGREEMENTS}/${agreementId}/version/${agreement.version?.id}/${UrlConstants.AGREEMENT_VERSION_PRICE_LIST}/${selectedRow}/edit`,
                                        icon: 'edit',
                                        text: messages.edit,
                                        isHidden: !selectedRow
                                    },
                                    {
                                        icon: 'ban',
                                        text: messages.delete,
                                        isHidden: !selectedRow,
                                        onClick: this.deactivateAgreementVersionPricelist
                                    }
                                ]} />
                            <PricelistList
                                isLoading={isLoadingAgreementVersionPriceLists || isDeletingAgreementPricelist} rows={pricelistAgreementVersions}
                                ref={this.priceListRef}
                                isInAgreementDetails
                                prefix={'pricelist_'}
                                secondPrefix={'pricelist_'}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.pricelist}
                                hiddenColumns={['unitSubGroup', 'currency']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationPriceList,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchAgreementVersionPricelists(newPage, {
                                        ...agreementVersionPriceListFilter,
                                        agreementVersionId: agreement.version?.id
                                    } as AgreementVersionPriceListsFilters);
                                    this.setState({ serverPaginationPriceList: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelPriceListChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModelPriceList}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelPriceList: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/specialprice`}>
                            <AgreementVersionSpecialPriceFilterComponent filters={agreementVersionSpecialPriceFilters} setDefaultState={this.setAgreementVersionSpecialPriceDefaultState}
                                applyFilters={this.applyAgreementVersionSpecialPriceFilters} onChange={this.onAgreementVersionSpecialPriceFilterChange} />
                            <AgreementVersionSpecialPriceList
                                isLoading={isLoadingAgreementVersionSpecialPrices || isDeletingAgreementSpecialPrice} ref={this.agreementVersionPriceListRef}
                                rows={agreementVersionSpecialPrices} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationSpecialPrice,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchAgreementVersionSpecialPrices(newPage, {
                                        ...agreementVersionSpecialPriceFilters,
                                        agreementId: agreement.id,
                                        agreementVersionId: agreement.version?.id
                                    } as AgreementVersionSpecialPriceFilters);
                                    this.setState({ serverPaginationSpecialPrice: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelVersionSpecialPriceChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModelAgreementVersionSpecialPL}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelAgreementVersionSpecialPL: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/discounts`}>
                            <ViewAgreementVersionDiscounts
                                isLoading={isLoadingDiscounts || isDeletingAgreementVersionTransitDiscount}
                                agreementVersionDiscounts={agreementVersionDiscounts} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationAgreementVTransitDiscount,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchAgreementVersionTransitDiscounts(agreement?.version?.id, newPage);
                                    this.setState({ serverPaginationAgreementVTransitDiscount: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelTransitDiscountChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModelAgreementVersionDiscount}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelAgreementVersionDiscount: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/customers`}>
                            <CustomerAgreementFiltersComponent filters={customerAgreementFilters} setDefaultState={this.setDefaultState} applyFilters={this.applyFilters}
                                onChange={this.onCustomerAgreementFilterChange}
                                actions={[
                                    {
                                        text: messages.add,
                                        icon: 'plus',
                                        isHidden: !!selectedRow,
                                        onClick: () => history.push(`/${UrlConstants.AGREEMENTS}/${agreementId}/add/customeragreement`)
                                    },
                                    {
                                        icon: 'angle-up',
                                        text: messages.promote,
                                        isHidden: !selectedRow,
                                        onClick: this.promoteCustomerAgreement
                                    }, {
                                        icon: 'angle-down',
                                        text: messages.demote,
                                        isHidden: !selectedRow,
                                        onClick: this.demoteCustomerAgreement
                                    },
                                    {
                                        href: `${UrlConstants.AGREEMENTS}/${agreementId}/customeragreement/${selectedRow}/edit`,
                                        icon: 'edit',
                                        isHidden: !selectedRow,
                                        text: messages.edit
                                    },
                                    {
                                        icon: 'ban',
                                        text: messages.delete,
                                        isHidden: !selectedRow,
                                        onClick: this.deactivateCustomerAgreement
                                    }
                                ]} />
                            <CustomerList isLoading={isLoadingCustomerAgreement || isUpdatingCustomerAgreement || isDeletingCustomerAgreement} ref={this.customerListRef}
                                prefix={'customer_'}
                                isCustomerAgreement
                                rows={customerAgreements} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.customer} hiddenColumns={['country', 'corporate', 'status', 'priorityRank', 'revenueThisYear', 'revenueLastYear']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationCustomerAgreement,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchCustomerAgreements(newPage, {
                                        ...customerAgreementFilters,
                                        agreementId: agreement.id
                                    } as CustomerAgreementFilters);
                                    this.setState({ serverPaginationCustomerAgreement: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelCustomerAgreementChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModelCustomer}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelCustomer: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/versions`}>
                            <AgreementVersionsList
                                isLoading={isLoadingAgreementVersions}
                                ref={this.agreementVersionListRef}
                                rows={agreementVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationAgreementVersion,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchAgreementVersions(agreement.id, newPage);
                                    this.setState({ serverPaginationAgreementVersion: newPage });
                                    localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_PAGINATION_CONSTANTS.AGREEMENT_VERSION_PAGINATION, newPage);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelAgreementVersionChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModelAgreementVersion}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModelAgreementVersion: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.AGREEMENTS}/:agreementId/:agreementVersionId/printOut`}>
                            <Loader isLoading={isRuningPrintAgreementVersion || isLoadingPrintAgreementVersion}>
                                <>
                                    <If isFalse={!!printAgreementVersion?.ready}>
                                        <Grid container justifyContent="flex-start">
                                            <Button onClick={this._runProcedurePrintAgreementVersion}><FormattedMessage {...messages.generatePrintAgreementPeriods} /></Button>
                                        </Grid>
                                    </If>
                                    <If isTrue={!!printAgreementVersion?.ready}>
                                        <PrintAgreementVersionPeriodList
                                            isLoading={isLoadingPrintAgreementVersionPeriod}
                                            ref={this.printAgreementVersionPeriodListRef}
                                            rows={printAgreementVersionPeriods}
                                            onPageChange={(nextPage: number, nextSize: number) => {
                                                const newPage = {
                                                    ...pagingPrintAgreementVersionPeriod,
                                                    page: nextPage,
                                                    size: nextSize,
                                                    sort: sortModelPrintAgreementVersionPeriod
                                                };

                                                fetchPrintAgreementVersionPeriod(newPage, {
                                                    ...filtersPrintAgreementVersionPeriod,
                                                    printAgreementVersionId: printAgreementVersion?.id
                                                } as PrintAgreementVersionPeriodFilters);

                                                this.setState({ pagingPrintAgreementVersionPeriod: newPage });
                                            }}
                                            onSortModelChange={(sortModel: GridSortModel) => this._onSortModelPrintAgreementVersionPeriodChange(sortModel)}
                                            columnVisibilityModel={columnVisibilityModelPrintAgreementVersionPeriod}
                                            onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                                this.setState({
                                                    columnVisibilityModelPrintAgreementVersionPeriod: newModel
                                                })
                                            }
                                        />
                                    </If>
                                </>
                            </Loader>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AgreementDetails));