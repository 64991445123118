import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import ActionRenderer from '../common/actionRenderer';
import Action from '../../interfaces/common/action';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import CancellationRuleSetType from '../../interfaces/output/cancellationRuleSetType';
import CancellationRuleFilter from '../../interfaces/filters/cancellationRuleFilter';
import { fetchCancellationTypes, getCancellationTypes } from '../../reducers/cancellationTypeReducer';
import { fetchPriorityTypes, getPriorityTypes } from '../../reducers/priorityTypeReducer';
import { fetchUnitTypes, getUnitTypes } from '../../reducers/unitTypeReducer';
import { fetchDepartureTypes, getDepartureTypes } from '../../reducers/departureTypeReducer';
import { fetchTerminals, getTerminals } from '../../reducers/terminalReducer';
import UnitType from '../../interfaces/output/unitType';
import Terminal from '../../interfaces/output/terminal';
import PriorityType from '../../interfaces/output/priorityType';
import DepartureType from '../../interfaces/output/departureType';

interface IFilterProps {
    filters: CancellationRuleFilter;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    departureType: LanguageUtils.createMessage('Departure type'),
    cancellationRuleSetType: LanguageUtils.createMessage('Cancellation rule set type'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CancellationRuleFilterComponent(props: IFilterProps): JSX.Element {
    const { onChange, applyFilters, setDefaultState, filters, actions } = props;

    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();

    const cancellationTypes = useSelector(getCancellationTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const departureTypes = useSelector(getDepartureTypes).content;
    const terminals = useSelector(getTerminals).content;

    useEffect(() => {
        dispatch(fetchCancellationTypes({ paging }));
        dispatch(fetchDepartureTypes({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchUnitTypes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromTerminalId}
                            onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalId}
                            onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitType>
                            options={unitTypes}
                            value={filters.unitTypeId}
                            onChange={(obj: UnitType | null) => onChange('unitTypeId', obj?.id)}
                            placeholder={messages.unitType}
                            compareFn={(o: UnitType) => o.id === filters.unitTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PriorityType>
                            options={priorityTypes}
                            value={filters.priorityTypeId}
                            onChange={(obj: PriorityType | null) => onChange('priorityTypeId', obj?.id)}
                            placeholder={messages.priorityType}
                            compareFn={(o: PriorityType) => o.id === filters.priorityTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<DepartureType>
                            options={departureTypes}
                            value={filters.departureTypeId}
                            onChange={(obj: DepartureType | null) => onChange('departureTypeId', obj?.id)}
                            placeholder={messages.departureType}
                            compareFn={(o: DepartureType) => o.id === filters.departureTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<CancellationRuleSetType>
                            options={cancellationTypes}
                            value={filters.cancellationTypeId}
                            onChange={(obj: CancellationRuleSetType | null) => onChange('cancellationTypeId', obj?.id)}
                            placeholder={messages.cancellationRuleSetType}
                            compareFn={(o: CancellationRuleSetType) => o.id === filters.cancellationTypeId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}