import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../../constants/constants';
import Paging from '../../../interfaces/common/paging';
import LanguageUtils from '../../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import Loader from '../../common/widgets/loader';
import {
    getCancellationRules,
    isLoadingCancellationRule,
    fetchCancellationRuleSetVersionById,
    resetCancellationRuleSetVersion,
    updateCancellationRuleSetVersion,
    deleteCancellationRuleSetVersion,
    getCancellationRuleSetVersion,
    isLoadingCancellationRuleSetVersionById,
    isUpdatingCancellationRuleSetVersion,
    isDeletingCancellationRuleSetVersion,
    fetchCancellationRules
} from '../../../reducers/cancellationRuleSetReducer';
import CancellationRuleSetVersion from '../../../interfaces/output/cancellationRuleSetVersion';
import ViewCancellationRuleSetVersion from './viewCancellationRuleSetVersion';
import CancellationRuleFilter from '../../../interfaces/filters/cancellationRuleFilter';
import CancellationRuleFilterComponent from '../cancellationRuleFilter';
import CancellationRuleList from '../cancellationRuleList';
import CancellationRule from '../../../interfaces/output/cancellationRule';
import Pagination from '../../../interfaces/common/pagination';

interface ICancellationRuleSetDetailsProps {
    classes: any;
    theme: Theme;
    fetchCancellationRuleSetVersionById: any;
    cancellationRuleSetVersion: CancellationRuleSetVersion;
    match: any;
    history: any;
    location: any;
    isLoading: boolean;
    isLoadingById: boolean;
    resetCancellationRuleSetVersion: () => void;
    isDeleting: boolean;
    isDeactivate: boolean;
    updateCancellationRuleSetVersion: any;
    deleteCancellationRuleSetVersion: any;
    fetchCancellationRules: any;
    isLoadingCancellationRule: any;
    cancellationRules: Pagination<CancellationRule>;
}

interface IServiceDetailsState {
    cancellationRuleSetVersion: CancellationRuleSetVersion;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    filters: CancellationRuleFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCancellationRuleSetVersionById: (cancellationRuleSetVersionId: number) => dispatch(fetchCancellationRuleSetVersionById(cancellationRuleSetVersionId)),
    resetCancellationRuleSetVersion: () => dispatch(resetCancellationRuleSetVersion()),
    fetchCancellationRules: (paging: Paging, filters: CancellationRuleFilter) => dispatch(fetchCancellationRules({
        paging,
        filters
    })),
    updateCancellationRuleSetVersion: (cancellationRuleSetVersion: CancellationRuleSetVersion) => dispatch(updateCancellationRuleSetVersion(cancellationRuleSetVersion)),
    deleteCancellationRuleSetVersion: (cancellationRuleSetVersionId: number) => dispatch(deleteCancellationRuleSetVersion(cancellationRuleSetVersionId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('Cancellation rule set version'),
    add: LanguageUtils.createMessage('Add'),
    properties: LanguageUtils.createMessage('Properties'),
    cancellationRule: LanguageUtils.createMessage('Cancellation rule')
};

const mapStoreToProps = (store: RootState) => {
    return {
        cancellationRuleSetVersion: getCancellationRuleSetVersion(store),
        isLoadingById: isLoadingCancellationRuleSetVersionById(store),
        isUpdating: isUpdatingCancellationRuleSetVersion(store),
        isDeleting: isDeletingCancellationRuleSetVersion(store),
        cancellationRules: getCancellationRules(store),
        isLoadingCancellationRule: isLoadingCancellationRule(store),
        isLoading: isLoadingCancellationRuleSetVersionById(store) || isDeletingCancellationRuleSetVersion(store) || isUpdatingCancellationRuleSetVersion(store) ||
            isLoadingCancellationRule(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class CancellationRuleSetVersionDetails extends Component<ICancellationRuleSetDetailsProps, IServiceDetailsState> {

    cancellationRuleSetVersionListRef: any;
    cancellationRuleListRef: any
    constructor(props: ICancellationRuleSetDetailsProps) {
        super(props);

        this.state = {
            cancellationRuleSetVersion: {} as CancellationRuleSetVersion,
            filters: {} as CancellationRuleFilter,
            menuItems: this.configureDrawer(this.props),
            paging: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: '',
                sort: 'desc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchCancellationRuleSetVersionById } = this.props;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        fetchCancellationRuleSetVersionById(cancellationRuleSetVersionId);
    }

    componentDidUpdate(prevProps: ICancellationRuleSetDetailsProps) {
        const { cancellationRuleSetVersion, isLoadingById, isDeleting, history } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(cancellationRuleSetVersion !== prevProps.cancellationRuleSetVersion) {
            this.setState({ cancellationRuleSetVersion });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingById && prevProps.isLoadingById)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeleting && prevProps.isDeleting) {
            history.push(`/${UrlConstants.CANCELLATION_RULE_SET}`);
        }
    }

    componentWillUnmount() {
        const { resetCancellationRuleSetVersion } = this.props;
        resetCancellationRuleSetVersion();
    }

    configureDrawer = (props: ICancellationRuleSetDetailsProps): ImmutableList<IMenuItem> => {
        return ImmutableList<IMenuItem>([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${props.match.params.cancellationRuleSetVersionId}/properties`,
            isSelected: PageUtils.isPageSelected(`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${props.match.params.cancellationRuleSetVersionId}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.cancellationRule,
            icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${props.match.params.cancellationRuleSetVersionId}/cancelationRules`,
            isSelected: PageUtils.isPageSelected(`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${props.match.params.cancellationRuleSetVersionId}/cancelationRules`, props.location.pathname)
        }
        ]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchCancellationRules } = this.props;
        const { cancellationRuleSetVersionId } = this.props.match.params;
        const { paging, sortModel, filters } = this.state;

        const newPagination = {
            ...paging,
            sort: sortModel
        };

        const newFilter = {
            ...filters,
            cancellationRuleSetVersionId: cancellationRuleSetVersionId
        } as CancellationRuleFilter;

        this.setState({
            sortModel: sortModel,
            paging: newPagination
        });

        if(selectedMenuItem?.id === 2) {
            fetchCancellationRules(newPagination, newFilter);
        }
    }

    deleteCancellationRuleSetVersion = () => {
        const { cancellationRuleSetVersionId } = this.props.match.params;
        const { deleteCancellationRuleSetVersion } = this.props;

        deleteCancellationRuleSetVersion(cancellationRuleSetVersionId);
    }

    getSelectedMenuItem = (state: IServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${cancellationRuleSetVersionId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deleteCancellationRuleSetVersion
                    }
                ];
            }
            case 2: { // cancellation rule set versions
                return [
                    {
                        href: `${UrlConstants.CANCELLATION_RULE}/${cancellationRuleSetVersionId}/add`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];

            }
            default: {
                return [];
            }
        }
    }

    applyFilters = () => {
        const { fetchCancellationRules } = this.props;
        const { paging, filters } = this.state;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        const newFilter = {
            ...filters,
            cancellationRuleSetVersionId: cancellationRuleSetVersionId
        } as CancellationRuleFilter;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination,
            filters: newFilter
        });

        fetchCancellationRules(newPagination, newFilter);

        if(this.cancellationRuleListRef !== null && this.cancellationRuleListRef !== undefined &&
            this.cancellationRuleListRef.current !== null && this.cancellationRuleListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.cancellationRuleListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { paging, filters } = this.state;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        const newFilter = { ...filters } as CancellationRuleFilter;
        (newFilter as any)[attribute] = value;
        newFilter.cancellationRuleSetVersionId = cancellationRuleSetVersionId;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchCancellationRules } = this.props;
        const { paging } = this.state;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        const newSortModel = [{
            field: '',
            sort: 'desc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
            cancellationRuleSetVersionId: cancellationRuleSetVersionId
        } as CancellationRuleFilter;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchCancellationRules(newPagination, newFilters);

        if(this.cancellationRuleListRef !== null && this.cancellationRuleListRef !== undefined &&
            this.cancellationRuleListRef.current !== null && this.cancellationRuleListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.cancellationRuleListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchCancellationRules } = this.props;
        const { sortModel, filters, paging } = this.state;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            const newFilters = {
                ...filters,
                cancellationRuleSetVersionId: cancellationRuleSetVersionId
            } as CancellationRuleFilter;

            fetchCancellationRules(newPaging, newFilters);
        }
    }

    render() {
        const { classes, isLoadingById, isLoading, cancellationRules, isLoadingCancellationRule, fetchCancellationRules } = this.props;
        const { cancellationRuleSetVersion, menuItems, filters, paging, columnVisibilityModel } = this.state;
        const { cancellationRuleSetVersionId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoading}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.ProductArena}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: cancellationRuleSetVersion.id,
                    icon: <FontAwesomeIcon icon="calendar-alt" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetVersionId/properties`}>
                            <Loader isLoading={isLoadingById}>
                                <ViewCancellationRuleSetVersion cancellationRuleSetVersion={cancellationRuleSetVersion} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.CANCELLATION_RULE_SET_VERSION}/:cancellationRuleSetVersionId/cancelationRules`}>
                            <>
                                <CancellationRuleFilterComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />

                                <CancellationRuleList
                                    rows={cancellationRules} isLoading={isLoadingCancellationRule}
                                    ref={this.cancellationRuleListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        const newFilters = {
                                            ...filters,
                                            cancellationRuleSetVersionId: cancellationRuleSetVersionId
                                        } as CancellationRuleFilter;
                                        fetchCancellationRules(newPage, newFilters);
                                        this.setState({
                                            paging: newPage,
                                            filters: newFilters
                                        });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CancellationRuleSetVersionDetails));