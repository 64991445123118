import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetCancellationRuleSet } from '../../../reducers/cancellationRuleSetReducer';
import { RootState } from '../../../setup';
import CancellationRuleSetForm from './cancellationRuleSetForm';

interface IAddCancellationRuleSetProps {
    classes: any;
    theme: Theme;
    match: any;
    users: any;
    steps: Array<number>;
    cancellationRuleSetId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetCancellationRuleSet: () => dispatch(resetCancellationRuleSet())
});

const mapStoreToProps = (store: RootState, ownProps: IAddCancellationRuleSetProps) => {
    return {
        cancellationRuleSetId: ownProps.match.params?.cancellationRuleSetId ? Number(ownProps.match.params?.cancellationRuleSetId) : undefined
    };
};

class AddCancellationRuleSet extends Component<IAddCancellationRuleSetProps> {
    componentWillUnmount() {
        resetCancellationRuleSet();
    }

    render() {
        const { classes, steps, cancellationRuleSetId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <CancellationRuleSetForm cancellationRuleSetId={cancellationRuleSetId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddCancellationRuleSet));