import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CancellationRuleSetFilter from '../interfaces/filters/cancellationRuleSetFilter';
import CancellationRuleSet from '../interfaces/output/cancellationRuleSet';
import CancellationRuleSetApi from '../api/cancellationRuleSetApi';
import {
    fetchCancellationRuleSetByIdSuccess, fetchCancellationRuleSetsError, fetchCancellationRuleSetsSuccess,
    fetchCancellationRuleSetTypesError, fetchCancellationRuleSetTypesSuccess, fetchCancellationRuleSetByIdError,
    fetchCancellationRuleSetVersionsSuccess,
    fetchCancellationRuleSetVersionsError,
    fetchCancellationRulesSuccess,
    fetchCancellationRulesError,
    fetchCancellationRuleByIdSuccess,
    fetchCancellationRuleByIdError,
    createCancellationRuleSuccess,
    createCancellationRuleError,
    updateCancellationRuleError,
    deleteCancellationRuleSuccess,
    deleteCancellationRuleError,
    updateCancellationRuleSuccess,
    createCancellationRuleSetSuccess,
    createCancellationRuleSetError,
    updateCancellationRuleSetSuccess,
    updateCancellationRuleSetError,
    deleteCancellationRuleSetSuccess,
    deleteCancellationRuleSetError,
    fetchCancellationRuleSetVersionByIdSuccess,
    fetchCancellationRuleSetVersionByIdError,
    createCancellationRuleSetVersionSuccess,
    createCancellationRuleSetVersionError,
    updateCancellationRuleSetVersionSuccess,
    updateCancellationRuleSetVersionError,
    deleteCancellationRuleSetVersionSuccess,
    deleteCancellationRuleSetVersionError
} from '../reducers/cancellationRuleSetReducer';
import CancellationRuleSetTypeFilter from '../interfaces/filters/cancellationRuleSetTypeFilter';
import CancellationRuleSetType from '../interfaces/output/cancellationRuleSetType';
import CancellationRuleSetVersionFilter from '../interfaces/filters/cancellationRuleSetVersionFilter';
import CancellationRuleSetVersion from '../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleFilter from '../interfaces/filters/cancellationRuleFilter';
import CancellationRule from '../interfaces/output/cancellationRule';

function* doFetchCancelationRuleSets(action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CancellationRuleSet> = yield call(CancellationRuleSetApi.fetchCancellationRuleSet, paging, filters);
        yield put(fetchCancellationRuleSetsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleSetsError(error as HttpErrorResponse));
    }
}

function* doFetchCancelationRuleSetById(action: PayloadAction<string>) {
    const capriSchduledJobId = action.payload;

    try {
        const response: CancellationRuleSet = yield call(CancellationRuleSetApi.fetchCancellationRuleSetById, capriSchduledJobId);
        yield put(fetchCancellationRuleSetByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleSetByIdError(error as HttpErrorResponse));
    }
}

function* doCreateCancellationRuleSet(action: PayloadAction<CancellationRuleSet>) {
    const cancellationRuleSet = action.payload;

    try {
        const response: CancellationRule = yield call(CancellationRuleSetApi.createCancellationRuleSet, cancellationRuleSet);

        yield put(createCancellationRuleSetSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCancellationRuleSetError(error as HttpErrorResponse));
    }
}

function* doUpdateCancellationRuleSet(action: PayloadAction<CancellationRuleSet>) {
    const cancellationRuleSet = action.payload;

    try {
        const response: CancellationRuleSet = yield call(CancellationRuleSetApi.updateCancellationRuleSet, cancellationRuleSet);

        yield put(updateCancellationRuleSetSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationRuleSetError(error as HttpErrorResponse));
    }
}

function* doDeleteCancellationRuleSet(action: PayloadAction<string>) {
    const cancellationRuleSetId = action.payload;

    try {
        const response: CancellationRuleSet = yield call(CancellationRuleSetApi.deleteCancellationRuleSetById, cancellationRuleSetId);
        yield put(deleteCancellationRuleSetSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteCancellationRuleSetError(error as HttpErrorResponse));
    }
}

function* doFetchCancelationRuleSetVersions(action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetVersionFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CancellationRuleSetVersion> = yield call(CancellationRuleSetApi.fetchCancellationRuleSetVersions, paging, filters);
        yield put(fetchCancellationRuleSetVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleSetVersionsError(error as HttpErrorResponse));
    }
}
function* doFetchCancelationRuleSetVersionById(action: PayloadAction<string>) {
    const cancellationRuleSetVersionId = action.payload;

    try {
        const response: CancellationRuleSetVersion = yield call(CancellationRuleSetApi.fetchCancellationRuleSetVersionById, cancellationRuleSetVersionId);
        yield put(fetchCancellationRuleSetVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleSetVersionByIdError(error as HttpErrorResponse));
    }
}

function* doCreateCancellationRuleSetVersion(action: PayloadAction<CancellationRuleSetVersion>) {
    const cancellationRuleSetVersion = action.payload;

    try {
        const response: CancellationRuleSetVersion = yield call(CancellationRuleSetApi.createCancellationRuleSetVersion, cancellationRuleSetVersion);

        yield put(createCancellationRuleSetVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCancellationRuleSetVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateCancellationRuleSetVersion(action: PayloadAction<CancellationRuleSetVersion>) {
    const cancellationRuleSetVersion = action.payload;

    try {
        const response: CancellationRuleSetVersion = yield call(CancellationRuleSetApi.updateCancellationRuleSetVersion, cancellationRuleSetVersion);

        yield put(updateCancellationRuleSetVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationRuleSetVersionError(error as HttpErrorResponse));
    }
}

function* doDeleteCancellationRuleSetVersion(action: PayloadAction<string>) {
    const cancellationRuleSetVersionId = action.payload;

    try {
        const response: CancellationRuleSetVersion = yield call(CancellationRuleSetApi.deleteCancellationRuleSetVersionById, cancellationRuleSetVersionId);
        yield put(deleteCancellationRuleSetVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteCancellationRuleSetVersionError(error as HttpErrorResponse));
    }
}

function* doFetchCancelationRuleSetTypes(action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetTypeFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CancellationRuleSetType> = yield call(CancellationRuleSetApi.fetchCancellationRuleSetType, paging, filters);
        yield put(fetchCancellationRuleSetTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleSetTypesError(error as HttpErrorResponse));
    }
}

function* doFetchCancelationRules(action: PayloadAction<{ paging: Paging, filters?: CancellationRuleFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CancellationRule> = yield call(CancellationRuleSetApi.fetchCancellationRule, paging, filters);
        yield put(fetchCancellationRulesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRulesError(error as HttpErrorResponse));
    }
}

function* doFetchCancelationRuleById(action: PayloadAction<string>) {
    const cancellationRuleId = action.payload;

    try {
        const response: CancellationRule = yield call(CancellationRuleSetApi.fetchCancellationRuleById, cancellationRuleId);
        yield put(fetchCancellationRuleByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationRuleByIdError(error as HttpErrorResponse));
    }
}
function* doCreateCancellationRule(action: PayloadAction<CancellationRule>) {
    const cancellationRule = action.payload;

    try {
        const response: CancellationRule = yield call(CancellationRuleSetApi.createCancellationRule, cancellationRule);

        yield put(createCancellationRuleSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCancellationRuleError(error as HttpErrorResponse));
    }
}

function* doUpdateCancellationRule(action: PayloadAction<CancellationRule>) {
    const cancellationRule = action.payload;

    try {
        const response: CancellationRule = yield call(CancellationRuleSetApi.updateCancellationRule, cancellationRule);

        yield put(updateCancellationRuleSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationRuleError(error as HttpErrorResponse));
    }
}

function* doDeleteCancellationRule(action: PayloadAction<string>) {
    const cancellationRuleId = action.payload;

    try {
        const response: CancellationRule = yield call(CancellationRuleSetApi.deleteCancellationRuleById, cancellationRuleId);
        yield put(deleteCancellationRuleSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteCancellationRuleError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('cancellationRuleSet/fetchCancellationRuleSets', doFetchCancelationRuleSets),
    takeLatest('cancellationRuleSet/fetchCancellationRuleSetById', doFetchCancelationRuleSetById),
    takeLatest('cancellationRuleSet/fetchCancellationRuleSetTypes', doFetchCancelationRuleSetTypes),
    takeLatest('cancellationRuleSet/fetchCancellationRuleSetVersions', doFetchCancelationRuleSetVersions),
    takeLatest('cancellationRuleSet/fetchCancellationRules', doFetchCancelationRules),
    takeLatest('cancellationRuleSet/fetchCancellationRuleById', doFetchCancelationRuleById),
    takeLatest('cancellationRuleSet/updateCancellationRule', doUpdateCancellationRule),
    takeLatest('cancellationRuleSet/deleteCancellationRule', doDeleteCancellationRule),
    takeLatest('cancellationRuleSet/createCancellationRule', doCreateCancellationRule),
    takeLatest('cancellationRuleSet/updateCancellationRuleSet', doUpdateCancellationRuleSet),
    takeLatest('cancellationRuleSet/deleteCancellationRuleSet', doDeleteCancellationRuleSet),
    takeLatest('cancellationRuleSet/createCancellationRuleSet', doCreateCancellationRuleSet),
    takeLatest('cancellationRuleSet/fetchCancellationRuleSetVersionById', doFetchCancelationRuleSetVersionById),
    takeLatest('cancellationRuleSet/updateCancellationRuleSetVersion', doUpdateCancellationRuleSetVersion),
    takeLatest('cancellationRuleSet/deleteCancellationRuleSetVersion', doDeleteCancellationRuleSetVersion),
    takeLatest('cancellationRuleSet/createCancellationRuleSetVersion', doCreateCancellationRuleSetVersion)
];

