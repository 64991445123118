import React, { forwardRef, useEffect } from 'react';
import CancellationRuleSet from '../../../interfaces/output/cancellationRuleSet';
import LanguageUtils from '../../../utils/LanguageUtils';
import { getCancellationRuleSetTypes,
    fetchCancellationRuleSetTypes, isLoagingCancellationRuleSetTypes } from '../../../reducers/cancellationRuleSetReducer';
import { useSelector, useDispatch } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import Loader from '../../common/widgets/loader';
import { FormControl, Grid } from '@mui/material';
import GenericTextField from '../../common/widgets/genericTextField';
import PageUtils from '../../../utils/pageUtils';
import CancellationRuleSetType from '../../../interfaces/output/cancellationRuleSetType';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import CancellationRuleSetStep1Validation from '../validations/cancellationRuleSetStep1Validation';

interface ICancellationRuleSetStep1Props {
    cancellationRuleSet: CancellationRuleSet;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    NAME: 'name',
    NAME_NO: 'nameNo',
    CANCELLATION_RULE_SET_TYPE: 'cancellationRuleSetType'

};

const messages = {
    name: LanguageUtils.createMessage('Name'),
    nameNo: LanguageUtils.createMessage('name no'),
    cancellationRuleSetType: LanguageUtils.createMessage('Cancellation rule set type')
};

const CancellationRuleSetFormStep1 = forwardRef((props: ICancellationRuleSetStep1Props, ref: any): JSX.Element => {
    const { cancellationRuleSet, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const cancellationRuleSetTypes = useSelector(getCancellationRuleSetTypes).content;
    const isLoadingObjects = [useSelector(isLoagingCancellationRuleSetTypes)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
   
    useEffect(() => {
        dispatch(fetchCancellationRuleSetTypes({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.NAME: {
                isValid = CancellationRuleSetStep1Validation.validateInputString(cancellationRuleSet.name);
                break;
            }
            case ATTRIBUTES.NAME_NO: {
                isValid = CancellationRuleSetStep1Validation.validateInputString(cancellationRuleSet.nameNo);
                break;
            }
            case ATTRIBUTES.CANCELLATION_RULE_SET_TYPE: {
                isValid = CancellationRuleSetStep1Validation.validateInputNumber(cancellationRuleSet.cancellationRuleSetType?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="name"
                        label={messages.name}
                        value={cancellationRuleSet.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="nameNo"
                        label={messages.nameNo}
                        value={cancellationRuleSet.nameNo ?? cancellationRuleSet.name_NO}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME_NO)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CancellationRuleSetType>
                            options={cancellationRuleSetTypes}
                            value={cancellationRuleSet.cancellationRuleSetType}
                            onChange={(obj: CancellationRuleSetType | null) => onChange('cancellationRuleSetType', obj)}
                            placeholder={messages.cancellationRuleSetType}
                            getOptionLabel={(option: CancellationRuleSetType) => option?.name || ''}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader >
    );
});

export default CancellationRuleSetFormStep1;
