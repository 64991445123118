import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import CancellationRuleSet from '../../../interfaces/output/cancellationRuleSet';
import { getCancellationRuleSet, isUpdating, isCreating, isLoadingCancellationRuleSetById,
    fetchCancellationRuleSetById, updateCancellationRuleSet, createCancellationRuleSet } from '../../../reducers/cancellationRuleSetReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import GenericStepper from '../../common/widgets/genericStepper';
import CancellationRuleSetStep1Validation from '../validations/cancellationRuleSetStep1Validation';
import CancellationRuleSetFormStep1 from './cancellationRuleSetFormStep1';

interface ICancellationRuleSetFormProps {
    cancellationRuleSetId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit cancellation rule set'),
    add: LanguageUtils.createMessage('Add cancellation rule set')
};

export default function CancellationRuleSetForm(props: ICancellationRuleSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { cancellationRuleSetId, steps, type } = props;
    const [cancellationRuleSet, setCancellationRuleSet] = React.useState<CancellationRuleSet>({ id: cancellationRuleSetId } as CancellationRuleSet);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsCancellationRuleSet = useSelector(getCancellationRuleSet);
    const propsIsUpdatingCancellationRuleSet = useSelector(isUpdating);
    const prevIsUpdatingCancellationRuleSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCancellationRuleSet);
    const propsIsCreatingCancellationRuleSet = useSelector(isCreating);
    const prevIsCreatingCancellationRuleSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCancellationRuleSet);
    const propsIsLoadingCancellationRuleSet = useSelector(isLoadingCancellationRuleSetById);
    const prevIsLoadingCancellationRuleSet = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCancellationRuleSet);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingCancellationRuleSet === true && !propsIsUpdatingCancellationRuleSet) || (prevIsCreatingCancellationRuleSet && !propsIsCreatingCancellationRuleSet)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingCancellationRuleSet, propsIsCreatingCancellationRuleSet]);

    useEffect(() => {
        if(cancellationRuleSetId) {
            dispatch(fetchCancellationRuleSetById(cancellationRuleSetId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingCancellationRuleSet && !propsIsLoadingCancellationRuleSet) {
            setCancellationRuleSet(propsCancellationRuleSet);
        }

    }, [propsIsLoadingCancellationRuleSet]);

    const onChange = (attribute: string, value: any) => {
        const newCancellationRuleSet = { ...cancellationRuleSet } as CancellationRuleSet;
        (newCancellationRuleSet as any)[attribute] = value;

        setCancellationRuleSet(newCancellationRuleSet);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <CancellationRuleSetFormStep1 onChange={onChange} cancellationRuleSet={cancellationRuleSet} />,
            validationFn: () => CancellationRuleSetStep1Validation.validateCancellationRuleSetForm(cancellationRuleSet),
            onNext: () => {
                if(!cancellationRuleSet.id) {
                    dispatch(createCancellationRuleSet(cancellationRuleSet));
                }
                else {
                    dispatch(updateCancellationRuleSet(cancellationRuleSet));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.CANCELLATION_RULE_SET}`;

            case FormType.Edit:
                return `/${UrlConstants.CANCELLATION_RULE_SET}/${cancellationRuleSet.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
