import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../../constants/constants';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import LanguageUtils from '../../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import Loader from '../../common/widgets/loader';
import {
    fetchCancellationRuleSetById, fetchCancellationRuleSetVersions, getCancellationRuleSet,
    getCancellationRuleSetVersions, isLoadingCancellationRuleSetVersion, resetCancellationRuleSet,
    updateCancellationRuleSet, deleteCancellationRuleSet,
    isUpdating, isLoadingCancellationRuleSetById,
    isDeleting,
    getCancellationRules,
    isLoadingCancellationRule
} from '../../../reducers/cancellationRuleSetReducer';
import CancellationRuleSet from '../../../interfaces/output/cancellationRuleSet';
import ViewCancellationRuleSet from './viewCancellationRuleSet';
import CancellationRuleSetVersionFilter from '../../../interfaces/filters/cancellationRuleSetVersionFilter';
import CancellationRuleSetVersion from '../../../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleSetVersionList from '../cancellationRuleSetVersion/cancellationRuleSetVersionList';

interface ICancellationRuleSetDetailsProps {
    classes: any;
    theme: Theme;
    fetchCancellationRuleSetById: any;
    cancellationRuleSet: CancellationRuleSet;
    match: any;
    history: any;
    location: any;
    isLoading: boolean;
    isLoadingById: boolean;
    isLoadingCancellationRuleSetVersion: boolean;
    cancellationRuleSetVersions: Pagination<CancellationRuleSetVersion>;
    resetCancellationRuleSet: () => void;
    fetchCancellationRuleSetVersions: (paging: Paging, filters: CancellationRuleSetVersionFilter) => void;
    isDeleting: boolean;
    isDeactivate: boolean;
    updateCancellationRuleSet: any;
    deleteCancellationRuleSet: any;
    isLoadingCancellationRule: boolean;
}

interface IServiceDetailsState {
    cancellationRuleSet: CancellationRuleSet;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    cancellationRuleSetVersionId?: number;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    filters: CancellationRuleSetVersionFilter;
    sortModelCancellationRule: GridSortModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCancellationRuleSetById: (cancellationRuleSetId: number) => dispatch(fetchCancellationRuleSetById(cancellationRuleSetId)),
    resetCancellationRuleSet: () => dispatch(resetCancellationRuleSet()),
    fetchCancellationRuleSetVersions: (paging: Paging, filters: CancellationRuleSetVersionFilter) => dispatch(fetchCancellationRuleSetVersions({
        paging,
        filters
    })),
    updateCancellationRuleSet: (cancellationRuleSet: CancellationRuleSet) => dispatch(updateCancellationRuleSet(cancellationRuleSet)),
    deleteCancellationRuleSet: (cancellationRuleSetId: number) => dispatch(deleteCancellationRuleSet(cancellationRuleSetId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('Cancellation rule set'),
    add: LanguageUtils.createMessage('Add'),
    properties: LanguageUtils.createMessage('Properties'),
    cancellationRuleSetVersions: LanguageUtils.createMessage('Cancellation rule set versions'),
    cancellationRule: LanguageUtils.createMessage('Cancellation rule')
};

const mapStoreToProps = (store: RootState) => {
    return {
        cancellationRuleSet: getCancellationRuleSet(store),
        isLoadingById: isLoadingCancellationRuleSetById(store),
        cancellationRuleSetVersions: getCancellationRuleSetVersions(store),
        isLoadingCancellationRuleSetVersion: isLoadingCancellationRuleSetVersion(store),
        isUpdating: isUpdating(store),
        isDeleting: isDeleting(store),
        cancellationRules: getCancellationRules(store),
        isLoadingCancellationRule: isLoadingCancellationRule(store),
        isLoading: isLoadingCancellationRuleSetById(store) || isLoadingCancellationRuleSetVersion(store) || isDeleting(store) ||
            isLoadingCancellationRule(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class CancellationRuleSetDetails extends Component<ICancellationRuleSetDetailsProps, IServiceDetailsState> {

    cancellationRuleSetVersionListRef: any;
    cancellationRuleListRef: any
    constructor(props: ICancellationRuleSetDetailsProps) {
        super(props);

        this.state = {
            cancellationRuleSet: {} as CancellationRuleSet,
            filters: {} as CancellationRuleSetVersionFilter,
            menuItems: this.configureDrawer(this.props),
            paging: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            cancellationRuleSetVersionId: undefined,
            sortModel: [{
                field: 'validFromDate',
                sort: 'desc' as GridSortDirection
            } as GridSortItem],
            sortModelCancellationRule: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchCancellationRuleSetById } = this.props;
        const { cancellationRuleSetId } = this.props.match.params;

        fetchCancellationRuleSetById(cancellationRuleSetId);
    }

    componentDidUpdate(prevProps: ICancellationRuleSetDetailsProps) {
        const { cancellationRuleSet, isLoadingById, isDeleting, history } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(cancellationRuleSet !== prevProps.cancellationRuleSet) {
            this.setState({ cancellationRuleSet });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingById && prevProps.isLoadingById)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeleting && prevProps.isDeleting) {
            history.push(`/${UrlConstants.CANCELLATION_RULE_SET}`);
        }
    }

    componentWillUnmount() {
        const { resetCancellationRuleSet } = this.props;
        resetCancellationRuleSet();
    }

    isHiddenCategory = (): boolean => {
        const { cancellationRuleSetVersionId } = this.state;

        return cancellationRuleSetVersionId !== undefined ? false : true;
    }

    configureDrawer = (props: ICancellationRuleSetDetailsProps): ImmutableList<IMenuItem> => {
        return ImmutableList<IMenuItem>([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `/${UrlConstants.CANCELLATION_RULE_SET}/${props.match.params.cancellationRuleSetId}/properties`,
            isSelected: PageUtils.isPageSelected(`/${UrlConstants.CANCELLATION_RULE_SET}/${props.match.params.cancellationRuleSetId}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.cancellationRuleSetVersions,
            icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `/${UrlConstants.CANCELLATION_RULE_SET}/${props.match.params.cancellationRuleSetId}/versions`,
            isSelected: PageUtils.isPageSelected(`/${UrlConstants.CANCELLATION_RULE_SET}/${props.match.params.cancellationRuleSetId}/versions`, props.location.pathname)
        }
        ]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchCancellationRuleSetVersions } = this.props;
        const { cancellationRuleSetId } = this.props.match.params;
        const { paging, sortModel, filters } = this.state;

        const newPagination = {
            ...paging,
            sort: sortModel
        };

        const newFilter = {
            ...filters,
            cancellationRuleSetId: cancellationRuleSetId
        } as CancellationRuleSetVersionFilter;

        this.setState({
            sortModel: sortModel,
            paging: newPagination
        });

        if(selectedMenuItem?.id === 2) {
            fetchCancellationRuleSetVersions(newPagination, newFilter);
        }
    }

    deleteCancellationRuleSet = () => {
        const { cancellationRuleSetId } = this.props.match.params;
        const { deleteCancellationRuleSet } = this.props;

        deleteCancellationRuleSet(cancellationRuleSetId);
    }

    getSelectedMenuItem = (state: IServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { cancellationRuleSetId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.CANCELLATION_RULE_SET}/${cancellationRuleSetId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deleteCancellationRuleSet
                    }
                ];
            }
            case 2: { // cancellation rule set versions
                return [
                    {
                        href: `${UrlConstants.CANCELLATION_RULE_SET_VERSION}/${cancellationRuleSetId}/add`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];

            }
            default: {
                return [];
            }
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchCancellationRuleSetVersions } = this.props;
        const { sortModel, filters, paging } = this.state;
        const { cancellationRuleSetId } = this.props.match.params;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            const newFilters = {
                ...filters,
                cancellationRuleSetId: cancellationRuleSetId
            } as CancellationRuleSetVersionFilter;

            fetchCancellationRuleSetVersions(newPaging, newFilters);

        }
    }

    render() {
        const { classes, isLoadingById, cancellationRuleSetVersions, isLoadingCancellationRuleSetVersion, fetchCancellationRuleSetVersions
        } = this.props;
        const { cancellationRuleSet, menuItems, paging, filters, columnVisibilityModel } = this.state;
        const { cancellationRuleSetId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingById}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.ProductArena}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: cancellationRuleSet.name,
                    icon: <FontAwesomeIcon icon="calendar-alt" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.CANCELLATION_RULE_SET}/:cancellationRuleSetId/properties`}>
                            <Loader isLoading={isLoadingById}>
                                <ViewCancellationRuleSet cancellationRuleSet={cancellationRuleSet} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.CANCELLATION_RULE_SET}/:cancellationRuleSetId/versions`}>
                            <>
                                <CancellationRuleSetVersionList
                                    rows={cancellationRuleSetVersions} isLoading={isLoadingCancellationRuleSetVersion}
                                    ref={this.cancellationRuleSetVersionListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        const newFilters = {
                                            ...filters,
                                            cancellationRuleSetId: cancellationRuleSetId
                                        } as CancellationRuleSetVersionFilter;
                                        fetchCancellationRuleSetVersions(newPage, newFilters);
                                        this.setState({
                                            paging: newPage,
                                            filters: newFilters
                                        });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CancellationRuleSetDetails));