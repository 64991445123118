import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';

interface CancellationRuleListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    cancellationRule: LanguageUtils.createMessage('Cancellation rule'),
    priorityRank: LanguageUtils.createMessage('Priority rank'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    loadingWeekday: LanguageUtils.createMessage('Loading weekday'),
    fromLoadingMonthDay: LanguageUtils.createMessage('From loading month day'),
    toLoadingMonthDay: LanguageUtils.createMessage('To loading month day'),
    loadingTrainNumber: LanguageUtils.createMessage('Loading train number'),
    fromLoadingHours: LanguageUtils.createMessage('From loading hours'),
    toLoadingHours: LanguageUtils.createMessage('To loading hours'),
    departureType: LanguageUtils.createMessage('Departure type'),
    cancellationMinutesBeforeLoading: LanguageUtils.createMessage('Cancellation minutes befor loading'),
    fromDaysBeforeLoadingDate: LanguageUtils.createMessage('From days before loading date'),
    fromHourLimit: LanguageUtils.createMessage('From hour limit'),
    toDaysBeforeLoadingDate: LanguageUtils.createMessage('To days before loading date'),
    toHourLimit: LanguageUtils.createMessage('To hour limit'),
    cancellationType: LanguageUtils.createMessage('Cancellation type')
};

const CancellationRuleList = forwardRef((props: CancellationRuleListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'fromTerminal',
                headerName: intl.formatMessage(messages.fromTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.fromTerminal?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminal', hiddenColumns)
            },
            {
                field: 'toTerminal',
                headerName: intl.formatMessage(messages.toTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.toTerminal?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toTerminal', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.unitType?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'priorityType',
                headerName: intl.formatMessage(messages.priorityType),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.priorityType?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priorityType', hiddenColumns)
            },
            {
                field: 'departureType',
                headerName: intl.formatMessage(messages.departureType),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.departureType?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('departureType', hiddenColumns)
            },
            {
                field: 'loadingWeekday',
                headerName: intl.formatMessage(messages.loadingWeekday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.loadingWeekday}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('loadingWeekday', hiddenColumns)
            },
            {
                field: 'fromLoadingMonthDay',
                headerName: intl.formatMessage(messages.fromLoadingMonthDay),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.fromLoadingMonthDay}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromLoadingMonthDay', hiddenColumns)
            },
            {
                field: 'toLoadingMonthDay',
                headerName: intl.formatMessage(messages.toLoadingMonthDay),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.toLoadingMonthDay}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toLoadingMonthDay', hiddenColumns)
            },
            {
                field: 'loadingTrainNo',
                headerName: intl.formatMessage(messages.loadingTrainNumber),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.loadingTrainNo}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('loadingTrainNo', hiddenColumns)
            },
            {
                field: 'fromLoadingHours',
                headerName: intl.formatMessage(messages.fromLoadingHours),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.fromLoadingHours}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromLoadingHours', hiddenColumns)
            },
            {
                field: 'toLoadingHours',
                headerName: intl.formatMessage(messages.toLoadingHours),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.toLoadingHours}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toLoadingHours', hiddenColumns)
            },
            {
                field: 'cancellationMinutesBeforeLoading',
                headerName: intl.formatMessage(messages.cancellationMinutesBeforeLoading),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.cancellationMinutesBeforeLoading}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('cancellationMinutesBeforeLoading', hiddenColumns)
            },
            {
                field: 'fromDaysBeforeLoadingDate',
                headerName: intl.formatMessage(messages.fromDaysBeforeLoadingDate),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.cancellationMinutesBeforeLoading}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromDaysBeforeLoadingDate', hiddenColumns)
            },
            {
                field: 'fromHourLimit',
                headerName: intl.formatMessage(messages.fromHourLimit),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.fromHourLimit}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromHourLimit', hiddenColumns)
            },
            {
                field: 'toDaysBeforeLoadingDate',
                headerName: intl.formatMessage(messages.toDaysBeforeLoadingDate),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.toDaysBeforeLoadingDate}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toDaysBeforeLoadingDate', hiddenColumns)
            },
            {
                field: 'toHourLimit',
                headerName: intl.formatMessage(messages.toHourLimit),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.toHourLimit}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toHourLimit', hiddenColumns)
            },
            {
                field: 'priorityRank',
                headerName: intl.formatMessage(messages.priorityRank),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.priorityRank}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priorityRank', hiddenColumns)
            },
            {
                field: 'cancellationType',
                headerName: intl.formatMessage(messages.cancellationType),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(cellValues)?.cancellationType?.name}
                        link={`/#/${UrlConstants.CANCELLATION_RULE}/cancelationRuleDetails/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('cancellationType', hiddenColumns)
            }

        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default CancellationRuleList;
