import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import ActionRenderer from '../../common/actionRenderer';
import GenericTextField from '../../common/widgets/genericTextField';
import Action from '../../../interfaces/common/action';
import CancellationRuleSetFilter from '../../../interfaces/filters/cancellationRuleSetFilter';
import PageUtils from '../../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCancellationRuleSetTypes, getCancellationRuleSetTypes } from '../../../reducers/cancellationRuleSetReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import CancellationRuleSetType from '../../../interfaces/output/cancellationRuleSetType';

interface IFilterProps {
    filters: CancellationRuleSetFilter;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    cancellationRuleSetType: LanguageUtils.createMessage('Cancellation rule set type'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CancellationRuleSetFilterComponent(props: IFilterProps): JSX.Element {
    const { onChange, applyFilters, setDefaultState, filters, actions } = props;

    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();

    const cancellationRuleSetTypes = useSelector(getCancellationRuleSetTypes).content;

    useEffect(() => {
        dispatch(fetchCancellationRuleSetTypes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<CancellationRuleSetType>
                            options={cancellationRuleSetTypes}
                            value={filters.cancellationRuleSetTypeId}
                            onChange={(obj: CancellationRuleSetType | null) => onChange('cancellationRuleSetTypeId', obj?.id)}
                            placeholder={messages.cancellationRuleSetType}
                            compareFn={(o: CancellationRuleSetType) => o.id === filters.cancellationRuleSetTypeId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}