import { List } from 'immutable';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../utils/validationUtils';
import CancellationRuleSet from '../../../interfaces/output/cancellationRuleSet';
import ValidationModel from '../../../utils/validationModel';
import { ensure } from '../../../utils/arrayUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class CancellationRuleSetStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateCancellationRuleSetForm(cancellationRuleSet: CancellationRuleSet): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            nameNo,
            cancellationRuleSetType
        } = cancellationRuleSet;

        const requiredFields =
            this.validateInputString(name) && this.validateInputString(nameNo) && this.validateInputNumber(cancellationRuleSetType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
