import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import CancellationRuleSet from '../interfaces/output/cancellationRuleSet';
import CancellationRuleSetVersion from '../interfaces/output/cancellationRuleSetVersion';
import CancellationRuleSetFilter from '../interfaces/filters/cancellationRuleSetFilter';
import CancellationRuleSetVersionFilter from '../interfaces/filters/cancellationRuleSetVersionFilter';
import CancellationRuleSetTypeFilter from '../interfaces/filters/cancellationRuleSetTypeFilter';
import CancellationRuleSetType from '../interfaces/output/cancellationRuleSetType';
import CancellationRule from '../interfaces/output/cancellationRule';
import CancellationRuleFilter from '../interfaces/filters/cancellationRuleFilter';

export type CancellationRuleSetState = {
    cancellationRuleSets: Pagination<CancellationRuleSet>;
    isLoading: boolean;
    cancellationRuleSetVersions: Pagination<CancellationRuleSetVersion>;
    isLoadingCancellationRuleSetVersion: boolean;
    error: HttpErrorResponse | undefined;
    cancellationRuleSet: CancellationRuleSet;
    cancellationRuleSetVersion: CancellationRuleSetVersion;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoadingById: boolean;
    isUpdatingCancellationRuleSetVersion: boolean;
    isDeletingCancellationRuleSetVersion: boolean;
    isCreatingCancellationRuleSetVersion: boolean;
    isLoadingCancellationRuleSetVersionById: boolean;
    isLoagingCancellationRuleSetTypes: boolean;
    cancellationRuleSetTypes: Pagination<CancellationRuleSetType>;
    cancellationRules: Pagination<CancellationRule>;
    isLoadingCancellationRule: boolean;
    isLoadingCancellationRuleById: boolean;
    isUpdatingCancellationRule: boolean;
    isDeletingCancellationRule: boolean;
    isCreatingCancellationRule: boolean;
    cancellationRule: CancellationRule;
};

const initialState: CancellationRuleSetState = {
    cancellationRuleSets: { content: [] as Array<CancellationRuleSet> } as Pagination<CancellationRuleSet>,
    cancellationRuleSetVersions: { content: [] as Array<CancellationRuleSetVersion> } as Pagination<CancellationRuleSetVersion>,
    isLoading: false,
    error: undefined,
    isLoadingCancellationRuleSetVersion: false,
    cancellationRuleSet: {} as CancellationRuleSet,
    cancellationRuleSetVersion: {} as CancellationRuleSetVersion,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isLoadingById: false,
    isUpdatingCancellationRuleSetVersion: false,
    isDeletingCancellationRuleSetVersion: false,
    isCreatingCancellationRuleSetVersion: false,
    isLoadingCancellationRuleSetVersionById: false,
    isLoagingCancellationRuleSetTypes: false,
    cancellationRuleSetTypes: { content: [] as Array<CancellationRuleSetType> } as Pagination<CancellationRuleSetType>,
    cancellationRules: { content: [] as Array<CancellationRule> } as Pagination<CancellationRule>,
    isLoadingCancellationRule: false,
    isLoadingCancellationRuleById: false,
    isCreatingCancellationRule: false,
    isDeletingCancellationRule: false,
    isUpdatingCancellationRule: false,
    cancellationRule: {} as CancellationRule

};

export const cancellationRuleSetSlice = createSlice({
    name: 'cancellationRuleSet',

    initialState,
    reducers: {
        //cancelation Rule
        fetchCancellationRules: (state: CancellationRuleSetState, _action: PayloadAction<{ paging: Paging, filters?: CancellationRuleFilter }>) => {
            state.isLoadingCancellationRule = true;
        },
        fetchCancellationRulesSuccess: (state: CancellationRuleSetState, action: PayloadAction<Pagination<CancellationRule>>) => {
            state.isLoadingCancellationRule = false;
            state.cancellationRules = action.payload;
        },
        fetchCancellationRulesError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCancellationRule = false;
            state.error = action.payload;
        },
        resetCancellationRules: (state: CancellationRuleSetState) => {
            state.cancellationRules = initialState.cancellationRules;
        },
        fetchCancellationRuleById: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isLoadingCancellationRuleById = true;
        },
        fetchCancellationRuleByIdSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRule>) => {
            state.isLoadingCancellationRuleById = false;
            state.cancellationRule = action.payload;
        },
        fetchCancellationRuleByIdError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCancellationRuleById = false;
        },
        creatCancellationRule: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRule>) => {
            state.isCreatingCancellationRule = true;
        },
        createCancellationRuleSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRule>) => {
            state.isCreatingCancellationRule = false;
            state.cancellationRule = action.payload;
        },
        createCancellationRuleError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingCancellationRule = false;
            state.error = action.payload;
        },
        updateCancellationRule: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRule>) => {
            state.isUpdatingCancellationRule = true;
        },
        updateCancellationRuleSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRule>) => {
            state.isUpdatingCancellationRule = false;
            state.cancellationRuleSet = action.payload;
        },
        updateCancellationRuleError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingCancellationRule = false;
            state.error = action.payload;
        },
        deleteCancellationRule: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isDeletingCancellationRule = true;
        },
        deleteCancellationRuleSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRule>) => {
            state.isDeletingCancellationRule = false;
            state.cancellationRule = action.payload;
        },
        deleteCancellationRuleError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeletingCancellationRule = false;
            state.error = action.payload;
        },
        resetCancellationRule: (state: CancellationRuleSetState) => {
            state.cancellationRule = initialState.cancellationRule;
        },
        //cancellation rule set

        fetchCancellationRuleSets: (state: CancellationRuleSetState, _action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetFilter }>) => {
            state.isLoading = true;
        },
        fetchCancellationRuleSetsSuccess: (state: CancellationRuleSetState, action: PayloadAction<Pagination<CancellationRuleSet>>) => {
            state.isLoading = false;
            state.cancellationRuleSets = action.payload;
        },
        fetchCancellationRuleSetsError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        resetCancellationRuleSets: (state: CancellationRuleSetState) => {
            state.cancellationRuleSets = initialState.cancellationRuleSets;
        },
        resetCancellationRuleSet: (state: CancellationRuleSetState) => {
            state.cancellationRuleSet = initialState.cancellationRuleSet;
        },
        fetchCancellationRuleSetById: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isLoadingById = true;
        },
        fetchCancellationRuleSetByIdSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSet>) => {
            state.isLoadingById = false;
            state.cancellationRuleSet = action.payload;
        },
        fetchCancellationRuleSetByIdError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingById = false;
        },
        createCancellationRuleSet: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRuleSet>) => {
            state.isCreating = true;
        },
        createCancellationRuleSetSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSet>) => {
            state.isCreating = false;
            state.cancellationRuleSet = action.payload;
        },
        createCancellationRuleSetError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateCancellationRuleSet: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRuleSet>) => {
            state.isUpdating = true;
        },
        updateCancellationRuleSetSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSet>) => {
            state.isUpdating = false;
            state.cancellationRuleSet = action.payload;
        },
        updateCancellationRuleSetError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteCancellationRuleSet: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deleteCancellationRuleSetSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSet>) => {
            state.isDeleting = false;
            state.cancellationRuleSet = action.payload;
        },
        deleteCancellationRuleSetError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        //cancelation rule set types
        fetchCancellationRuleSetTypes: (state: CancellationRuleSetState, _action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetTypeFilter }>) => {
            state.isLoagingCancellationRuleSetTypes = true;
        },
        fetchCancellationRuleSetTypesSuccess: (state: CancellationRuleSetState, action: PayloadAction<Pagination<CancellationRuleSet>>) => {
            state.isLoagingCancellationRuleSetTypes = false;
            state.cancellationRuleSetTypes = action.payload;
        },
        fetchCancellationRuleSetTypesError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoagingCancellationRuleSetTypes = false;
            state.error = action.payload;
        },
        resetCancellationRuleSetTypes: (state: CancellationRuleSetState) => {
            state.cancellationRuleSetTypes = initialState.cancellationRuleSetTypes;
        },
        //Cancellation rule set versions
        fetchCancellationRuleSetVersions: (state: CancellationRuleSetState, _action: PayloadAction<{ paging: Paging, filters?: CancellationRuleSetVersionFilter }>) => {
            state.isLoadingCancellationRuleSetVersion = true;
        },
        fetchCancellationRuleSetVersionsSuccess: (state: CancellationRuleSetState, action: PayloadAction<Pagination<CancellationRuleSetVersion>>) => {
            state.isLoadingCancellationRuleSetVersion = false;
            state.cancellationRuleSetVersions = action.payload;
        },
        fetchCancellationRuleSetVersionsError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCancellationRuleSetVersion = false;
            state.error = action.payload;
        },
        fetchCancellationRuleSetVersionById: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isLoadingCancellationRuleSetVersionById = true;
        },
        fetchCancellationRuleSetVersionByIdSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSetVersion>) => {
            state.isLoadingCancellationRuleSetVersionById = false;
            state.cancellationRuleSetVersion = action.payload;
        },
        fetchCancellationRuleSetVersionByIdError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCancellationRuleSetVersionById = false;
        },
        resetCancellationRuleSetVersions: (state: CancellationRuleSetState) => {
            state.cancellationRuleSetVersions = initialState.cancellationRuleSetVersions;
        },
        resetCancellationRuleSetVersion: (state: CancellationRuleSetState) => {
            state.cancellationRuleSetVersion = initialState.cancellationRuleSetVersion;
        },
        createCancellationRuleSetVersion: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRuleSetVersion>) => {
            state.isCreatingCancellationRuleSetVersion = true;
        },
        createCancellationRuleSetVersionSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSetVersion>) => {
            state.isCreatingCancellationRuleSetVersion = false;
            state.cancellationRuleSetVersion = action.payload;
        },
        createCancellationRuleSetVersionError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingCancellationRuleSetVersion = false;
            state.error = action.payload;
        },
        updateCancellationRuleSetVersion: (state: CancellationRuleSetState, _action: PayloadAction<CancellationRuleSet>) => {
            state.isUpdatingCancellationRuleSetVersion = true;
        },
        updateCancellationRuleSetVersionSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSetVersion>) => {
            state.isUpdatingCancellationRuleSetVersion = false;
            state.cancellationRuleSetVersion = action.payload;
        },
        updateCancellationRuleSetVersionError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingCancellationRuleSetVersion = false;
            state.error = action.payload;
        },
        deleteCancellationRuleSetVersion: (state: CancellationRuleSetState, _action: PayloadAction<number>) => {
            state.isDeletingCancellationRuleSetVersion = true;
        },
        deleteCancellationRuleSetVersionSuccess: (state: CancellationRuleSetState, action: PayloadAction<CancellationRuleSetVersion>) => {
            state.isDeletingCancellationRuleSetVersion = false;
            state.cancellationRuleSetVersion = action.payload;
        },
        deleteCancellationRuleSetVersionError: (state: CancellationRuleSetState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeletingCancellationRuleSetVersion = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCancellationRuleSetById, fetchCancellationRuleSetByIdSuccess, fetchCancellationRuleSetVersions, fetchCancellationRuleSets,
    fetchCancellationRuleSetsError, fetchCancellationRuleSetsSuccess, fetchCancellationRuleSetVersionsError, fetchCancellationRuleSetVersionsSuccess,
    fetchCancellationRuleSetByIdError, updateCancellationRuleSet, updateCancellationRuleSetError, updateCancellationRuleSetSuccess,
    createCancellationRuleSet, createCancellationRuleSetError, createCancellationRuleSetSuccess, fetchCancellationRuleSetTypes,
    fetchCancellationRuleSetTypesError, fetchCancellationRuleSetTypesSuccess, resetCancellationRuleSet, deleteCancellationRuleSet, deleteCancellationRuleSetError,
    deleteCancellationRuleSetSuccess, deleteCancellationRuleSetVersion, deleteCancellationRuleSetVersionError, deleteCancellationRuleSetVersionSuccess,
    fetchCancellationRules, fetchCancellationRulesError, fetchCancellationRulesSuccess, resetCancellationRules,
    fetchCancellationRuleById, fetchCancellationRuleByIdError, fetchCancellationRuleByIdSuccess, resetCancellationRule,
    updateCancellationRule,updateCancellationRuleSuccess, updateCancellationRuleError, updateCancellationRuleSetVersion,updateCancellationRuleSetVersionError,
    updateCancellationRuleSetVersionSuccess, deleteCancellationRule,
    deleteCancellationRuleError, deleteCancellationRuleSuccess, creatCancellationRule, createCancellationRuleError, createCancellationRuleSuccess,
    createCancellationRuleSetVersionSuccess, createCancellationRuleSetVersionError, resetCancellationRuleSetVersion, fetchCancellationRuleSetVersionById,
    fetchCancellationRuleSetVersionByIdError, fetchCancellationRuleSetVersionByIdSuccess, createCancellationRuleSetVersion
} = cancellationRuleSetSlice.actions;
export const isLoadingCancellationRuleSets = (state: RootState): boolean => state.cancellatioRuleSet.isLoading;
export const getCancellationRuleSets = (state: RootState): Pagination<CancellationRuleSet> => state.cancellatioRuleSet.cancellationRuleSets;
export const getCancellationRuleSet = (state: RootState): CancellationRuleSet => state.cancellatioRuleSet.cancellationRuleSet;
export const isLoadingCancellationRuleSetById = (state: RootState): boolean => state.cancellatioRuleSet.isLoadingById;
export const getCancellationRuleSetVersions = (state: RootState): Pagination<CancellationRuleSetVersion> => state.cancellatioRuleSet.cancellationRuleSetVersions;
export const getCancellationRuleSetVersion = (state: RootState): CancellationRuleSetVersion => state.cancellatioRuleSet.cancellationRuleSetVersion;
export const isLoadingCancellationRuleSetVersion = (state: RootState): boolean => state.cancellatioRuleSet.isLoadingCancellationRuleSetVersion;
export const isLoadingCancellationRuleSetVersionById = (state: RootState): boolean => state.cancellatioRuleSet.isLoadingCancellationRuleSetVersionById;
export const getCancellationRuleSetTypes = (state: RootState): Pagination<CancellationRuleSetType> => state.cancellatioRuleSet.cancellationRuleSetTypes;
export const getCancellationRules = (state: RootState): Pagination<CancellationRule> => state.cancellatioRuleSet.cancellationRules;
export const isLoadingCancellationRule = (state: RootState): boolean => state.cancellatioRuleSet.isLoadingCancellationRule;
export const isCreating = (state: RootState): boolean => state.cancellatioRuleSet.isCreating;
export const isUpdating = (state: RootState): boolean => state.cancellatioRuleSet.isUpdating;
export const isDeleting = (state: RootState): boolean => state.cancellatioRuleSet.isDeleting;
export const getCancellationRule = (state: RootState): CancellationRule => state.cancellatioRuleSet.cancellationRule;
export const isLoadingCancellationRuleById = (state: RootState): boolean => state.cancellatioRuleSet.isLoadingCancellationRuleById;
export const isCreatingCancellationRule = (state: RootState): boolean => state.cancellatioRuleSet.isCreatingCancellationRule;
export const isUpdatingCancellationRule = (state: RootState): boolean => state.cancellatioRuleSet.isUpdatingCancellationRule;
export const isDeletingCancellationRule = (state: RootState): boolean => state.cancellatioRuleSet.isDeletingCancellationRule;
export const isLoagingCancellationRuleSetTypes = (state: RootState): boolean => state.cancellatioRuleSet.isLoagingCancellationRuleSetTypes;
export const isCreatingCancellationRuleSetVersion = (state: RootState): boolean => state.cancellatioRuleSet.isCreatingCancellationRuleSetVersion;
export const isUpdatingCancellationRuleSetVersion = (state: RootState): boolean => state.cancellatioRuleSet.isUpdatingCancellationRuleSetVersion;
export const isDeletingCancellationRuleSetVersion = (state: RootState): boolean => state.cancellatioRuleSet.isDeletingCancellationRuleSetVersion;

export default cancellationRuleSetSlice.reducer;